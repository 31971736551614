import { createSlice } from "@reduxjs/toolkit";

function check_session() {
  if (localStorage.getItem("token") !== null) {
    const data = JSON.parse(localStorage.getItem("token"));
    if (data) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

const initialState = {
  user:
    localStorage.getItem("token") !== null
      ? JSON.parse(localStorage.getItem("token"))
      : null,
  isLoggedIn: check_session(),
};

export const authSlice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    logoutUser: (state) => {
      localStorage.removeItem("token");
      state.isLoggedIn = false;
      state.subscribed = false;
      state.user = null;
    },
    checkLogin: (state) => {
      state.isLoggedIn = check_session();
    },
    loginUserState: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      localStorage.setItem("token", JSON.stringify(action.payload));
    },
  },
});

export const { logoutUser, loginUserState, checkLogin } = authSlice.actions;

export default authSlice.reducer;
