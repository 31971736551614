export const legal_en_Translation = {
  terms_and_conditions: {
    title: "Terms and Conditions",
    intro:
      "This End User License Agreement is a valid agreement between You and the Company. Please, read this EULA carefully. The rules of the EULA are binding upon You. If You do not agree to be bound by the rules of the EULA, please, do not use the WebApp. If You continue to use the WebApp, this will mean that You have read and understood the EULA and agree to be bound by it.",
    definitions: {
      title: "1. DEFINITIONS AND TERMS",
      description:
        "In order to understand the rules of the EULA clearly you shall familiarize yourself with the meaning of terms used in the EULA. In this EULA the following terms, when capitalized, shall have the following meanings:",
      definitions_list: {
        account:
          "“Account” – means a personal account of a user of the Platform services, which is provided by the Platform Administrator under the Platform Terms of Use or similar document. The Account is accessed with a Platform ID (account name) and a password.",
        company:
          "“Company”, “We” – mean RBCOM LTD, a company incorporated in the Singapore at the following address: 1 Raffles Place, #40-02, One Raffles Place Tower 1, Singapore 048616.",
        content:
          "“Content” – means all kinds of content, including, among other, data, texts, images, videos, sounds, links, software and other pieces of information in any form, which are made available to You through the WebApp.",
        device:
          "“Device” – means a smartphone, a tablet or another similar mobile device that You either own or otherwise legally control exclusively for Your personal non-commercial use.",
        eula: "“EULA” – means this End User License Agreement, which is a binding agreement between You and the Company. The EULA may incorporate other documents, such as Privacy Policy that regulate relations between You and Us with regard to using the WebApp. If a document represents a part of the EULA, it has a clear indication thereof.",
        intellectual_property_rights:
          "“Intellectual Property Rights” – means, collectively, rights under any patent, trademark, copyright and trade secret laws, and any other intellectual property or proprietary rights recognized in any country or jurisdiction worldwide, including, without limitation, moral or similar rights.",
        subscription:
          "“Subscription” – means a period, during which You use the WebApp as a whole or certain additional Content for a fee.",
        user_generated_content:
          "“User-generated Content” – means any form of Content, including, among other, data, texts, images, videos, sounds, links, that have been posted by users to online platforms, for example, social networks (e.g. Instagram, Facebook).",
        you: "“You” – means an individual using the WebApp.",
      },
    },
    changes_of_eula: {
      title: "2. CHANGES OF THE EULA",
      list: {
        changes_intro:
          "We may introduce changes to this EULA from time to time. You shall regularly check the latest version of the EULA at the link, available on the web-page.",
        changes_effective:
          "If You do not agree with the terms of the updated EULA, You are not allowed to use the WebApp starting from the day, on which the updated EULA takes effect. In this case You shall not use the WebApp and cancel renewal of your Subscriptions.",
      },
    },
    device: {
      title: "3. DEVICE",
      list: {
        use_non_owned_device:
          "If You intend use the WebApp on any Device not owned by You, You must have the owner’s permission to do so. You will be responsible for complying with this EULA whether or not You own the Device.",
        access_to_non_owned_device:
          "If You do not own the Device, You are allowed to use the WebApp only through Your Account.",
        responsibility:
          "If You use the Device together with other people, please, make sure that nobody except You can use the WebApp from Your Account. All actions taken in Your Account are considered Your actions in disregard of who actually takes such actions. You are fully responsible for all actions taken in Your Account.",
        removal:
          "If You decide to sell or otherwise dispose of the Device, You shall delete the WebApp from the Device.",
      },
    },
    capacity_and_age: {
      title: "4. LEGAL CAPACITY AND AGE",
      list: {
        over_16:
          "The WebApp is designed for users over 16. If You use the WebApp, You confirm that You are at least 16 years old and have full legal capacity to enter into the Agreement.",
        between_16_18:
          "If You are between 16 and 18 years old, Your parent, guardian or other representative of legal age has to check and agree to this EULA on Your behalf. If You use the WebApp, You confirm that Your parent, guardian or other representative of legal age has agreed to the terms of this EULA and permits You to use the WebApp.",
      },
    },
    intellectual_property: {
      title: "5. INTELLECTUAL PROPERTY",
      list: {
        license_grant_and_webapp_use_terms:
          "We hereby grant You a non-exclusive, personal, limited, revocable and non-transferable license to access, download and run the Webapp only on Your Device, provided that You agree to comply with all the terms and conditions of this EULA. Please, take into consideration that the WebApp is licensed, not sold, to You.",
        intellectual_property_rights_and_reservation_of_rights:
          "The Company or the licensor of the Company is the only holder of Intellectual Property Rights with regard to the WebApp. The Company retains all rights, title and interest in and to the WebApp and reserves all rights not expressly granted to You in this EULA.",
        restrictions_on_transferring_rights_to_webapp:
          "You may not sell, resell, distribute, redistribute, rent, lease, sublicense, assign, copy, or in any other way transfer or grant any rights to the WebApp to any third party.",
        restrictions_on_network_distribution_and_access:
          "You may not distribute or make the WebApp available over any network where it could be downloaded or used by multiple users at the same time, except when it is expressly authorized by Us.",
        prohibitions_on_interfering_with_webapp_functionality:
          "You may not use any automatic or manual device or process to interfere with the proper working of the WebApp. However, You may use any kind of device or process to delete the WebApp from Your Device.",
        prohibitions_on_decompiling_and_reverse_engineering:
          "You may not decompile, disassemble, reverse engineer or create derivative works of the WebApp or any parts thereof. All modifications or enhancements to the WebApp remain the sole property of Company.",
        prohibitions_on_unlawful_use_and_malicious_activity:
          "You may not use the WebApp in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with this EULA or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into the WebApp.",
      },
    },
    subscription_and_trial_period: {
      title: "6. SUBSCRIPTION AND TRIAL PERIOD",
      list: {
        subscription_basis_and_fee_variation:
          "This WebApp as a whole or its certain part (additional Content) is provided on a paid subscription basis. The fee may vary depending on the period of the Subscription duration.",
        automatic_renewal_and_account_charge:
          "The Subscription will renew automatically for the same period at the end of the Subscription at the cost of the chosen package. Your Account will be charged for the renewed Subscription within 24 hours prior to the end of the current Subscription.",
        cancellation_of_subscription_renewal:
          "You may cancel renewal of the Subscription at least 24 hours before the end of the Subscription: in this case the WebApp as a whole or its additional Content will be available to You until the end of the Subscription. In order to cancel automatic renewal of the Subscription, please, change subscription settings of Your Account. After the end of the Subscription the WebApp as a whole or its additional Content will be available only if a new Subscription is purchased.",
        manual_cancellation_required:
          "Your subscription will not stop automatically. You must cancel automatic renewal of Your subscription in order not to be charged with the cost of the new Subscription.",
        free_trial_period_and_auto_conversion:
          "The WebApp provides for a free 7-day trial period. You may use the WebApp or its additional Content free of charge during this period. Please, take into consideration that at the end of the trial period it will automatically transform into a paid Subscription for the shortest period available and Your Account will be charged for the cost of such Subscription, unless You cancel the Subscription through the subscription settings of Your Account at least 24 hours prior to the end of the free trial period.",
      },
    },
    fees_and_purchase_terms: {
      title: "7. FEES AND PURCHASE TERMS",
      list: {
        subscription_costs_availability:
          "The costs of all Subscriptions are available on the web-page.",
        payment_processing_by_platform_administrator:
          "When You purchase a Subscription, the Platform Administrator charges Your Account and collects money on Our behalf. The payment is processed by a payment processing system implemented by the Platform Administrator.",
        no_access_to_payment_information:
          "Please, kindly notice that We neither process Your payment nor have any access to Your payment information, such as, among other, Your bank card data, Your name or any other related information.",
        questions_on_payment_processing:
          "Should You have any question with regard to payment processing, please, submit Your issue to user support service of the Platform Administrator.",
        account_charge_timing:
          "Your Account is charged with the cost of the new Subscription within 24 hours prior to the end of the current Subscription. If You cancel automatic renewal within the last 24 hours of the Subscription, such cancellation will take effect at the end of the new Subscription.",
        no_refunds_and_immediate_content_access:
          "Please, kindly notice that We do not make refunds. The digital content is made available to You immediately after the Subscription fee is charged to Your Account.",
      },
    },
    user_generated_content: {
      title: "8. USER-GENERATED CONTENT",
      list: {
        exposure_to_objectionable_content:
          "As far as You may access posts of other Instagram users, You may be exposed to potentially objectionable User-generated Content. Please, take into account that the Company neither controls nor pre-checks any User-generated Content posted on any social network.",
        modifications_and_censorship:
          "Any kind of modifications or censorship by the Company with regard to the User-generated Content may be considered violation of human rights or copyright and may potentially mislead You.",
        handling_objectionable_content:
          "If You have been exposed to any kind of objectionable content, You may use functionality of the respective social network to track and delete objectionable User-generated Content.",
        company_disclaimer:
          "Under no circumstances shall the Company be responsible for any User-generated Content.",
      },
    },
    disclaimer: {
      title: "9. DISCLAIMER",
      list: {
        general_disclaimer_of_warranties:
          "To the fullest extent permitted by applicable law, We and Our affiliates, officers, employees, agents, partners, and licensors expressly disclaim all warranties of any kind, express or implied, as to the accuracy, adequacy or completeness of any of the information contained in Our WebApp or created by the services.",
        content_provided_as_is:
          "The Content in Our WebApp is provided to You on an “as is” basis, without any warranties as to merchantability or fitness for a particular purpose or use. We do not guarantee that You will attain any anticipated result from the use of the WebApp.",
        no_warranty_on_specifics: {
          title: "We make no warranty that:",
          a: "the WebApp will meet Your requirements;",
          b: "the WebApp will be error-free, secure, or uninterrupted;",
          c: "any WebApp functionality errors will be corrected;",
          d: "the quality of the WebApp will meet Your expectations.",
        },
        limitation_of_liability:
          "We shall have no responsibility or liability for any errors or omissions. We are not responsible for any damages, whether direct or indirect, special or consequential, suffered by You or by any other person from the use of the WebApp, even if You have been advised of the possibility of such damages. No advice or information, whether oral or written, obtained by You from Us or Our WebApp shall create any warranty not expressly stated in the EULA. Under no circumstances shall Our liability or of any of Our affiliates pursuant to any cause of action, whether in contract, tort, or otherwise, exceed the fee paid by You for access to the WebApp. Furthermore, We shall have no responsibility or liability for delays or failures due to circumstances beyond Our control.",
      },
    },
    transfer_of_rights: {
      title: "10. TRANSFER OF RIGHTS",
      list: {
        transfer_by_company:
          "We may transfer Our rights and obligations under this EULA to any other organization or individual without Your consent. We will always tell You in writing if this happens and We will ensure that the transfer will not affect Your rights under the EULA.",
        transfer_by_user:
          "You may only transfer Your rights or Your obligations under this EULA to another person if We agree in writing.",
      },
    },
    termination_of_rights: {
      title: "11. TERMINATION OF RIGHTS",
      list: {
        termination_for_breach:
          "We may end Your rights to use the WebApp at any time by contacting You if You have broken the rules of this EULA in a serious way. If what You have done can be put right We will give You a reasonable opportunity to do so.",
        actions_upon_termination: {
          title: "If We end Your rights to use the WebApp:",
          a: "You must stop all activities authorized by this EULA, including Your use of the WebApp.",
          b: "You must delete or remove the WebApp from all Devices and immediately destroy all copies of the WebApp which You have and confirm to Us that You have done this.",
        },
      },
    },
    miscellaneous: {
      title: "12. MISCELLANEOUS",
      list: {
        separation_of_paragraphs:
          "Each of the paragraphs of this EULA operates separately. If any court or relevant authority decides that any of them is unlawful, the remaining paragraphs will remain in full force and effect.",
        delay_in_enforcement:
          "Even if We delay enforcing this EULA, We can still enforce it later. If We do not insist immediately that You do anything You are required to do under this EULA terms, or if We delay taking steps against You in respect of Your breaking the EULA, that will not mean that You do not have to do those things and it will not prevent Us taking steps against You at a later date.",
        governing_law:
          "This EULA shall be governed and construed in accordance with the laws of Dublin, Ireland, without regard to its conflict of law provisions.",
        dispute_resolution:
          "If any dispute, controversy or difference arises between You and the Company, it shall be settled amicably through negotiations. In case such dispute, controversy or difference cannot be settled amicably through negotiations within a thirty 30-day period it shall be settled by the Ireland Arbitration Association in accordance with its International Expedited Procedures.",
        number_of_arbitrators: "The number of arbitrators shall be one.",
        place_of_arbitrators:
          "The place of arbitration shall be Dublin, Ireland.",
        language_of_arbitrators:
          "The arbitration shall be held, and the award rendered, in English.",
        expense:
          "Each party (i.e. You and the Company) shall bear its own expenses,but the parties shall share equally the expenses of the arbitration tribunal.",
        support:
          " Support. If You want to learn more about the WebApp or have any problems using it, please, contact Us at <tag>support@ask-me.app</tag>",
      },
    },
  },
  privacy_policy: {
    title: "Privacy and Policy",
    commitment_title: "Our Commitment to Your Privacy",
    commitment_text:
      "We respect and protect the privacy of our WebApp users. This Privacy Policy explains how we collect and use your information and is part of our Terms of Use when you use our WebApp. By using our WebApp and the service provided via the WebApp (“Service”), you consent to our Privacy Policy. If you don’t agree, please don’t use our WebApp.",
    info_collection_title: "What information do we collect?",
    info_collection_text:
      "If you make purchases or buy a subscription, our third-party billing service providers will collect information such as your full name, credit card number, and billing address. If you email us, we may use your email address to reply to your email. Information Sent by Your Mobile Device. We collect certain information that your mobile device sends when you use our WebApp, like a device identifier, user settings and the operating system of your device, as well as information about your use of our Services. Location Information. When you use our WebApp, we may collect and store information about your location by converting your IP address into a rough geo-location or by accessing your mobile device’s GPS coordinates or coarse location if you enable location services on your device. We may use location information to improve and personalize our Services for you. If you do not want us to collect location information, you may disable that feature on your mobile device.",
    info_storage_title: "Where do we store your information?",
    info_storage_text:
      "Your information may be stored in our own servers or in servers owned by third-party cloud storage providers. Third-party storage providers may not use your information except as provided in this Privacy Policy.",
    info_usage_title: "How do we use your information?",
    info_usage_text: "Information we collect from you might be used:",
    info_storage_duration_title: "How long do we store your information?",
    info_storage_duration_text:
      "We intend to store some of your information indefinitely. What about links to other websites? We may provide links to or compatibility with other websites. However, we’re not responsible for the privacy practices employed by those websites or the information or content they contain.",
    info_protection_title: "How do we protect your information?",
    info_protection_text:
      "We use appropriate physical, electronic, and other procedures to safeguard and secure the information we collect. However, please be aware that the Internet is an inherently unsafe environment, and that hackers are constantly working to defeat security measures. Thus, we cannot guarantee that your information will not be accessed, disclosed, altered or destroyed, and you accept this risk.",
    user_protection_title: "How can you protect your information?",
    user_protection_text:
      "We urge you to take steps to keep your personal information safe by not sharing it with others or posting it online.",
    disclosure_title: "Do we disclose any information to outside parties?",
    disclosure_text:
      "We do not collect, sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information (PII) for commercial or marketing purposes, except as stated herein. Personal information (and non- personal information) collected through the Site may be shared with companies and organizations that perform services on our behalf (for example, companies that provide data management or other support services to us such as data storage and Web hosting services). We may share your personal information (and non-personal information) with third parties to serve you relevant advertising and market our products, but we won’t sell your personal information. Information Disclosed in Connection with Business Transactions. If we are acquired by a third party as a result of a transaction such as a merger, acquisition or asset sale or if our assets are acquired by a third party in the event we go out of business or enter bankruptcy, some or all of our assets, including your PII, may be disclosed or transferred to a third-party acquirer in connection with the transaction. Information Disclosed for Our Protection and the Protection of Others. We cooperate with government and law enforcement officials or private parties to enforce and comply with the law. We may disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate: (i) to respond to claims, legal process (including subpoenas); (ii) to protect our property, rights and safety and the property, rights and safety of a third party or the public in general; and (iii) to stop any activity that we consider illegal, unethical or legally actionable activity.",
    not_for_children_title: "Not Intended for Children",
    not_for_children_text:
      "Our WebApp and Service are not intended for children under the age of 16. We do not knowingly or specifically collect information from or about children under the age of 16.",
    policy_changes_title: "Changes to our Privacy Policy",
    policy_changes_text:
      "If we decide to change our Privacy Policy, we will post those changes on this page. We may also, but are not required to, send you an email notice. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.",
    contact_title: "Contact Us",
    contact_text:
      "If you have questions about our Privacy Policy, please contact us at <tag>support@ask-me.app</tag>",
  },
};

export const screens_en_Translation = {
  common_text: {
    male: "Male",
    female: "Female",
    terms_and_conditions: "Terms and Conditions",
    privacy_policy: "Privacy Policy",
    copy_right: "Copyright ® 2024. All Rights Reserved.",
    continue: "Continue",
    login_text: "LOG IN",
  },
  landing_page: {
    title: "Personalized AI Courses",
    personalized_freelance_plan: "Create Your Path to Freedom",
    start_freelance_business:
      "Start a Freelance Business: <tag/>Be Your Own Boss",
    launch_successful_business:
      "Launch Your Successful Freelancing Business Today",
    select_text: "Select your gender:",
  },
  career_plan: {
    title: "Career freedom plan according to your age",
    age_18_24: "18-24",
    age_25_34: "25-34",
    age_35_44: "35-44",
    age_45_plus: "45+",
  },
  community: {
    title: "$36,000",
    sub_title: "could be where your freelance venture begins",
  },
  goal_selection: {
    question: "What is your goal?",
    priority: "Please select your first priority",
    grow_wealth: "Grow wealth",
    be_boss: "Be my own boss",
    travel_more: "Travel more",
    other: "Other",
  },
  income_source: {
    question: "What is your current income source?",
    job: "9 to 5 Job",
    freelance: "Freelance",
    other: "Other",
  },
  income_level: {
    question: "Choose your current income level",
    low: "Low",
    medium: "Medium",
    high: "High",
  },
  financial_situation: {
    question: "How would you describe your financial situation?",
    struggling: "I'm struggling to meet my financial goals",
    stability: "I would like to have more stability",
    unsure: "I'm not sure",
    comfortable: "I'm comfortable",
    range_30_50k: "$30,000-$50,000",
    range_50_100k: "$50,000-$100,000",
    range_above_100k: "More than $100,000",
  },
  remote_work: {
    question: "Do you value the flexibility of remote work in your career?",
    yes: "Yes",
    maybe: "Maybe",
    no: "No",
  },
  work_family_balance: {
    question: "Do you struggle to <tag/> balance work and family time?",
    absolutely: "Absolutely",
    somewhat: "Somewhat",
    work_life_balance: "I have a work-life balance",
    prefer_not_to_say: "Prefer not to say",
  },
  work_situation: {
    question: "What kind of work situation do you aspire to have?",
    remote_balance: "Remote balance",
    stable_routine: "Stable routine",
    exploring_options: "I'm exploring options",
  },
  travel_while_working: {
    question: "Do you wish you could travel while working?",
    dream: "It's a dream",
    traveling: "I'm traveling",
    sometimes: "Sometimes",
    not_priority: "Not a priority",
  },
  content_writing_knowledge: {
    question: "Rate your knowledge in content writing",
    upwork_info:
      "According to Upwork, freelance content writer makes around $42,000 annually",
    need_more_info: "I need more information",
    know_everything: "I know everything",
  },
  ai_content_creation: {
    question:
      "Did you know that AI tools can make creation of content even easier?",
    no_news: "No, this is news to me",
    curious: "I'm curious",
    heard_of_it: "Yes, I've heard of it",
  },
  digital_marketing_knowledge: {
    question: "Rate your knowledge in digital marketing",
    upwork_info:
      "According to Upwork, digital marketer makes around $100,000 annually.",
    need_more_info: "I need more information",
    know_everything: "I know everything",
  },
  promote_business_ability: {
    question: "Rate your ability to <tag/> promote business",
    upwork_info: "70% of freelancers find their job online, and you can too.",
    need_more_info: "I need more information",
    know_everything: "I know everything",
  },
  ai_promotion_help: {
    question: "Did you know that AI tools can help you with promotion?",
    no_news: "No, this is news to me",
    curious: "I'm curious",
    heard_of_it: "Yes, I've heard of it",
  },
  job_interests: {
    question: "Choose jobs you might be interested in",
    digital_marketing: "Digital Marketing",
    smm: "SMM",
    affiliate_marketing: "Affiliate Marketing",
    dropshipping: "Dropshipping",
    content_writing: "Content Writing",
    copywriting: "Copywriting",
    translation: "Translation",
    graphic_design: "Graphic Design",
    blogging: "Blogging",
    illustration: "Illustration",
    continue: "Continue",
  },
  freelancing_readiness: {
    question: "Rate your readiness to start freelancing",
    need_more_info: "I need more information",
    know_everything: "I know everything",
  },
  freelance_platforms: {
    question: "Freelance platforms you have heard of",
    freelancer: "Freelancer",
    linkedin: "LinkedIn",
    fiverr: "Fiverr",
    guru: "Guru",
    toptal: "Toptal",
    upwork: "Upwork",
    designhill: "Designhill",
    flexjobs: "Flexjobs",
    behance: "Behance",
    wwr: "WWR",
  },
  summary_screen: {
    title: "Summary of your <line/> <tag>freelancing profile</tag>",
    readiness_score: "Readiness Score",
    result_perfect: "Result: Perfect",
    required_score: "Required - 75",
    impressive_score: "Impressive score to succeed in freelancing",
    description:
      "Freelancers enjoy greater flexibility and earning potential, with 61% of them earning more than in traditional jobs. You can also benefit from freelance.",
    mindset: "MINDSET",
    entrepreneur: "Enterpreneur",
    motivation: "MOTIVATION",
    high: "High",
    time_for_freelancing: "TIME",
    for_freelancing: "For freelancing",
    knowledge: "KNOWLEDGE",
    beginner: "Beginner",
  },
  special_achievement: {
    question: "Is there something <tag/> special you wish to <tag/> achieve?",
    options: {
      buy_house: "Buy a house",
      perfect_wedding: "A perfect wedding",
      vacation: "Vacation",
      buy_car: "Buy a new car",
      retirement: "Worry-free retirement",
    },
    continue: "Continue",
  },
  time_to_goal: {
    question: "How much time are you ready to spend to achieve your goal?",
    options: {
      five_min: "5 min/day",
      ten_min: "10 min/day",
      fifteen_min: "15 min/day",
      twenty_min: "20 min/day",
    },
  },
  personal_plan: {
    title: "Your personal plan to <tag/> career freedom",
    based_on_answers:
      "Based on your answers, we expect you to gain necessary freelance skills of",
    confident_freelancer_by: "Confident freelancer by {{month}}, {{year}}",
    confident: "Confident",
    freelancer: "Freelancer",
    beginner: "Beginner",
  },
  designing_plan: {
    title: "We are designing your career freedom plan…",
    people_chosen: "70,000+ people",
    chosen_freelanz_app: "have chosen AskMe.app",
    exploring_financial_freedom: "Exploring Financial Freedom",
    essential_guide: "Essential Guide",
    great_discovery: "Great Discovery",
  },
  testimonials: {
    sean: {
      name: "Sean",
      text: "AskMe empowers financial freedom through freelance work. Streamlined interface and diverse opportunities make it a valuable resource for new freelancers like me.",
    },
    angel: {
      name: "Angel",
      text: "I'm thrilled about my learning journey with AskMe, where a wide range of freelance topics are explored in their courses. The copywriting stands out as my favorite.",
    },
    trisha: {
      name: "Trisha",
      text: "AskMe inspired me to pursue knowledge in technology and AI, pushing me to overcome my anxiety and aim for great heights!",
    },
  },
  email_signup: {
    title:
      "Enter your email to get your personal <tag>Career Freedom</tag> plan!",
    privacy_note:
      "We respect your privacy. Your email can make the advertising experience more relevant <tag> Privacy Policy </tag>.",
    email_label: "Your Email",
    email_placeholder: "Enter valid email address",
    success_message: "Well done!",
  },
  login: {
    title: "Log In",
    description: "Enter your registered email address to enter the app",
    email_label: "Your email",
    email_placeholder: "Enter valid email address",
    login_button: "Log In",
    no_account: "Not have an account? <tag>Register</tag>",
  },
  career_plan_ready: {
    title: "Your 4-week Career Freedom Plan is ready!",
    readiness_level: "Your readiness level",
    after_4_weeks: "After 4 weeks",
    now: "Now",
    week_labels: {
      week_1: "Week 1",
      week_2: "Week 2",
      week_3: "Week 3",
      week_4: "Week 4",
    },
  },
  otp: {
    title: "Enter OTP",
    message:
      "A one-time password has been sent to your <tag/> Email: {{email}}",
    submit_button: "Submit",
    resend_otp: "Resend OTP",
  },
  payment: {
    email: "email",
    email_placeholder: "Enter valid email address",
    card_number: "CARD NUMBER",
    expires_on: "EXPIRES ON",
    process_card: "Process Card",
    discount_expires: "Discount expires in",
    try_now: "TRY NOW",
    min: "Min",
    sec: "Sec",
    now: "Now",
    goal: "Goal",
    flexibility: "Flexibility",
    earning_power: "Earning power",
    limited: "Limited",
    high: "High",
    basic: "Basic",
    disclaimer: "This is not a guarantee or promise of results.",
    trial_offer: "Try <tag>AskMe 7 days for $1</tag>, and you will:",
    // trial_offer: "Try <tag>AskMe 4 Weeks,</tag> and you will:",
    benefits: {
      ai_tools: "Master AI tools that can boost your income",
      additional_sources: "Discover additional income sources",
      skills: "Obtain skills of a Freelance Pro and kickstart your earnings",
      beginner_lessons: "Access to beginner-friendly lessons",
      guidance: "Guidance for competence and freelancing success",
    },
    trial: "7-Day Trial",
    // trial: "4-WEEK PLAN",
    payment_methods: "Credit <tag>/</tag> Debit Card",
    guaranteed_checkout: "Guaranteed <tag>Safe</tag> Checkout",
    what_you_get: "What You Get",
    income_know_how: "Freelance income know-how",
    lessons: "Insightful, easy and fun lessons",
    personal_coach:
      "24/7 chat with your personal coach to get all answers and reduce mistakes",
    users_love: "Users Love Our Plans",
    user_reviews: {
      review_1:
        "I genuinely value the lessons you all provide on various subjects. I'm determined to excel in this area, and I've gained valuable insights. It's been a rewarding experience, and I've absorbed a wealth of knowledge.",
      review_2:
        "I must acknowledge, this course ranks among the finest I've ever undertaken. It imparts practical insights into the realm of digital marketing.",
      review_3:
        "These courses have been exceptionally beneficial. I've never encountered an application that provides such comprehensive information like Coursiv. The remarkable aspect is that I'm constantly learning new strategies and tactics for promoting myself online.",
    },
    career_titles: {
      digital_marketing: "Digital Marketing",
      content_writer: "Content Writer",
      graphic_designer: "Graphic Designer",
    },
    career_info: {
      digital_marketing: "$100,000 per year",
      content_writer: "$42,000 per year",
      graphic_designer: "$90,000 per year",
    },
    no_degree: "No need to be a professional",
    no_university_degree: "No need for a university degree",
    work_at_your_pace: "Work at your own pace and terms",
    people_often_ask: "People often ask",
    faqs: {
      career_freedom_plan:
        "What is the Career Freedom Plan about, and how can I get started?",
      commitment_concerns:
        "I'm concerned about committing to freelancing and then giving up midway.",
      technical_skills:
        "Is it possible to learn freelancing without specialized technical skills?",
    },
    faq_answers: {
      career_freedom_plan:
        "AskMe offers an opportunity for anyone to delve into freelancing by providing accessible learning materials. The Career Freedom Plan focuses on teaching essential freelance skills in a user-friendly, gamified format, tackling potentially complex concepts in an easily understandable manner.",
      commitment_concerns:
        "We understand your worries, which is why AskMe ensures a beginner-friendly experience. Our AI mentor is available to provide guidance throughout your journey, requiring minimal effort on your part.",
      technical_skills:
        "Absolutely! Our learning resources are designed to simplify technical topics, making them accessible to all. While having some technical background can be beneficial, it's not a prerequisite for success in freelancing.",
    },
    money_back_guarantee: "100% Money-Back Guarantee",
    guarantee_message:
      "We believe that our plan can work for you and you will get results in 4 weeks! We are even willing to return your money if you don't see results but this mainly depends on you. Please double check our <tag>Refund Policy</tag> to understand all the requirements.",
  },
};
