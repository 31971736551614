export const SupportIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <defs>
      <linearGradient
        id="linear-gradient4"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#7d1fe8" />
        <stop offset="1" stopColor="#cd28b0" />
      </linearGradient>
    </defs>
    <g id="Group_111" data-name="Group 111" transform="translate(-36 -186)">
      <rect
        id="Rectangle_Copy_3"
        data-name="Rectangle Copy 3"
        width="34"
        height="34"
        rx="8"
        transform="translate(36 186)"
        fill="url(#linear-gradient4)"
      />
      <path
        id="Icon_awesome-question-circle"
        data-name="Icon awesome-question-circle"
        d="M16.563,8.563a8,8,0,1,1-8-8A8,8,0,0,1,16.563,8.563ZM8.777,3.208a4.178,4.178,0,0,0-3.76,2.057.388.388,0,0,0,.088.524l1.119.849a.387.387,0,0,0,.538-.068c.576-.731.971-1.155,1.848-1.155.659,0,1.474.424,1.474,1.063,0,.483-.4.731-1.049,1.1C8.276,8,7.272,8.528,7.272,9.853v.129a.387.387,0,0,0,.387.387H9.466a.387.387,0,0,0,.387-.387V9.939c0-.918,2.683-.956,2.683-3.441C12.536,4.627,10.6,3.208,8.777,3.208Zm-.215,8a1.484,1.484,0,1,0,1.484,1.484A1.486,1.486,0,0,0,8.563,11.208Z"
        transform="translate(44.438 194.438)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const LegalIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <defs>
      <linearGradient
        id="linear-gradient5"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#7d1fe8" />
        <stop offset="1" stopColor="#cd28b0" />
      </linearGradient>
    </defs>
    <g id="Group_111" data-name="Group 111" transform="translate(-36 -186)">
      <rect
        id="Rectangle_Copy_3"
        data-name="Rectangle Copy 3"
        width="34"
        height="34"
        rx="8"
        transform="translate(36 186)"
        fill="url(#linear-gradient5)"
      />
      <path
        id="Icon_awesome-info-circle"
        data-name="Icon awesome-info-circle"
        d="M8.563.563a8,8,0,1,0,8,8A8,8,0,0,0,8.563.563Zm0,3.548A1.355,1.355,0,1,1,7.208,5.466,1.355,1.355,0,0,1,8.563,4.111ZM10.369,12.3a.387.387,0,0,1-.387.387H7.143a.387.387,0,0,1-.387-.387V11.53a.387.387,0,0,1,.387-.387H7.53V9.079H7.143a.387.387,0,0,1-.387-.387V7.917a.387.387,0,0,1,.387-.387H9.208a.387.387,0,0,1,.387.387v3.226h.387a.387.387,0,0,1,.387.387Z"
        transform="translate(44.437 194.437)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const SettingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <defs>
      <linearGradient
        id="linear-gradient7"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#7d1fe8" />
        <stop offset="1" stopColor="#cd28b0" />
      </linearGradient>
    </defs>
    <g id="Group_111" data-name="Group 111" transform="translate(-36 -186)">
      <rect
        id="Rectangle_Copy_3"
        data-name="Rectangle Copy 3"
        width="34"
        height="34"
        rx="8"
        transform="translate(36 186)"
        fill="url(#linear-gradient7)"
      />
      <path
        id="XMLID_273_"
        d="M18.051,11.848l-.323-.187a1.228,1.228,0,0,1,0-2.127l.323-.187a1.841,1.841,0,0,0,.674-2.517l-.614-1.064a1.844,1.844,0,0,0-2.517-.674l-.323.186a1.228,1.228,0,0,1-1.842-1.064V3.842A1.845,1.845,0,0,0,11.587,2H10.358A1.845,1.845,0,0,0,8.516,3.842v.373A1.228,1.228,0,0,1,6.674,5.279l-.323-.187a1.845,1.845,0,0,0-2.517.674L3.22,6.83a1.841,1.841,0,0,0,.674,2.517l.324.187a1.228,1.228,0,0,1,0,2.127l-.323.187a1.841,1.841,0,0,0-.674,2.517l.614,1.064a1.844,1.844,0,0,0,2.517.674l.323-.186A1.228,1.228,0,0,1,8.516,16.98v.373a1.845,1.845,0,0,0,1.842,1.842h1.228a1.845,1.845,0,0,0,1.842-1.842v-.373a1.228,1.228,0,0,1,1.842-1.064l.323.187a1.845,1.845,0,0,0,2.517-.674l.614-1.064A1.841,1.841,0,0,0,18.051,11.848Zm-7.078,1.82a3.07,3.07,0,1,1,3.07-3.07,3.074,3.074,0,0,1-3.07,3.07Z"
        transform="translate(42.028 192.403)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const RedLogoutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
  >
    <g id="Group_111" data-name="Group 111" transform="translate(-36 -186)">
      <rect
        id="Rectangle_Copy_3"
        data-name="Rectangle Copy 3"
        width="34"
        height="34"
        rx="8"
        transform="translate(36 186)"
        fill="#ee1e52"
      />
      <path
        id="Icon_open-account-logout"
        data-name="Icon open-account-logout"
        d="M5.982,0V1.994h7.976v9.97H5.982v1.994h9.97V0ZM3.988,3.988,0,6.979,3.988,9.97V7.976h7.976V5.982H3.988Z"
        transform="translate(45.024 196.021)"
        fill="#fff"
      />
    </g>
  </svg>
);
