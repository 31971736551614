import { t } from "i18next";
import React from "react";
import svg1 from "../../assets/svgs/Mask Group 12.svg";

const Plan = () => {
  return (
    <>
      <div className="plan-card">
        <div className="position-relative">
          <img
            src={svg1}
            className="w-100 object-fit-cover"
            alt="plan details"
          ></img>
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <p className="monthly-follower-text text-nowrap fs-14 open-sans-800">
              {t("Get 1-2K Followers per Month!")}
            </p>
            <PercentageText />
          </div>
        </div>
        <div className="price-container">
          <div className="trial-price-wrapper d-flex justify-content-between align-items-center">
            <p className="fs-14 fw-regular text-white m-0">
              {t("7-Day Trial")}
            </p>
            <p className="fs-14 open-sans-800 text-white m-0">
              <span className="opacity-50 text-decoration-line-through">
                {t("$9.99")}
              </span>
              {t("$1.00")}
            </p>
          </div>
          <div className="plan-card-line"></div>
          <div className="total-price-wrapper d-flex justify-content-between align-items-center">
            <p className="fs-14 open-sans-800 text-white m-0">
              {t("Total Today")}
            </p>
            <p className="fs-14 open-sans-800 text-white m-0">{t("$1.00")}</p>
          </div>
        </div>
      </div>
    </>
  );
};

const PercentageText = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="172.96"
    height="66.614"
    viewBox="0 0 172.96 66.614"
  >
    <defs>
      <filter
        id="Path_114"
        x="0"
        y="0"
        width="172.96"
        height="66.614"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dx="5" dy="5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.361" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_114)">
      <path
        id="Path_114-2"
        data-name="Path 114"
        d="M-81.69-18.128v-9.418h24.853v9.418Zm64.1-15.7a16.253,16.253,0,0,1-2.682,9.287l-14,21.256H-46.307l9.941-14.454a16.318,16.318,0,0,1-11.021-4.971A15.523,15.523,0,0,1-51.6-33.825a15.354,15.354,0,0,1,4.742-11.642Q-42.121-49.98-34.6-49.98q7.587,0,12.3,4.513A15.408,15.408,0,0,1-17.594-33.825Zm-21.649-4.611a5.944,5.944,0,0,0-1.9,4.611,5.944,5.944,0,0,0,1.9,4.611A6.632,6.632,0,0,0-34.6-27.481a6.706,6.706,0,0,0,4.644-1.7,5.921,5.921,0,0,0,1.9-4.644,5.921,5.921,0,0,0-1.9-4.644,6.706,6.706,0,0,0-4.644-1.7A6.632,6.632,0,0,0-39.243-38.436ZM18.018-8.874Q13.08-2.366,4.316-2.366t-13.7-6.508q-4.938-6.508-4.938-17.3t4.938-17.3q4.938-6.508,13.7-6.508t13.7,6.508q4.938,6.508,4.938,17.3T18.018-8.874ZM-3.86-26.173q0,13.6,8.175,13.6t8.175-13.6q0-13.6-8.175-13.6T-3.86-26.173ZM45.684-30.98a10.961,10.961,0,0,1-8.077,3.237A11.071,11.071,0,0,1,29.5-30.98a10.672,10.672,0,0,1-3.27-7.881,10.672,10.672,0,0,1,3.27-7.881,11.071,11.071,0,0,1,8.11-3.237,11.08,11.08,0,0,1,8.077,3.172,10.667,10.667,0,0,1,3.237,7.947A10.726,10.726,0,0,1,45.684-30.98Zm-14.029,20.5,29.562-32.7,4.317,2.485L35.971-7.925Zm3.4-25.834a3.26,3.26,0,0,0,2.485,1.046,3.314,3.314,0,0,0,2.518-1.079,3.562,3.562,0,0,0,1.014-2.518,3.562,3.562,0,0,0-.981-2.583,3.391,3.391,0,0,0-2.551-1.014,3.26,3.26,0,0,0-2.485,1.046,3.592,3.592,0,0,0-.981,2.551A3.592,3.592,0,0,0,35.056-36.311ZM69.033-5.734A10.961,10.961,0,0,1,60.955-2.5a11.071,11.071,0,0,1-8.11-3.237,10.672,10.672,0,0,1-3.27-7.881,10.672,10.672,0,0,1,3.27-7.881,11.071,11.071,0,0,1,8.11-3.237A10.961,10.961,0,0,1,69.033-21.5a10.726,10.726,0,0,1,3.237,7.881A10.726,10.726,0,0,1,69.033-5.734ZM58.4-11.065a3.26,3.26,0,0,0,2.485,1.046A3.314,3.314,0,0,0,63.408-11.1a3.562,3.562,0,0,0,1.014-2.518,3.562,3.562,0,0,0-.981-2.583,3.391,3.391,0,0,0-2.551-1.014A3.26,3.26,0,0,0,58.4-16.166a3.592,3.592,0,0,0-.981,2.551A3.592,3.592,0,0,0,58.4-11.065Z"
        transform="translate(86.19 54.48)"
        fill="#fff"
        stroke="rgba(244,84,26,0.27)"
        strokeWidth="1"
      />
    </g>
  </svg>
);
export default Plan;
