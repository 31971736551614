import { t } from "i18next";
import React from "react";
import Button from "../common/Button";

const InstagramGuide = ({ scrollToPlan }) => {
  return (
    <div className="payment-section-pv payment-padding-x text-center">
      <div className="payment-section-v mt-0">
        <InstagramIcon />
      </div>
      <p className="text-center fs-20 open-sans-800">
        {t(
          "Your Instagram Account Is Safe: No login credentials are required."
        )}
      </p>
      <p className="text-start fs-14 lh-20 fw-regular">
        {t(
          "At AskMe, we ensure that our influencer plans fully comply with Instagram's TOS. Unlike many other services that require you to share your account login to provide social growth, working with us guarantees that your Instagram account remains completely secure."
        )}
      </p>
      <Button onClick={scrollToPlan} label={t("Start Growing Today")} />
    </div>
  );
};

const InstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="57.643"
    height="67.51"
    viewBox="0 0 57.643 67.51"
  >
    <defs>
      <linearGradient
        id="linear-gradient8"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#7d1fe8" />
        <stop offset="1" stopColor="#cd28b0" />
      </linearGradient>
    </defs>
    <path
      id="Union_4"
      data-name="Union 4"
      d="M-3232.13-3202.584a4.835,4.835,0,0,1-.657-.178c-27.9-9.7-27.446-33.262-27.147-48.853l0-.122c.032-.711.051-1.409.06-2.1a7.165,7.165,0,0,1,6.756-7.01c7.783-.434,13.805-2.965,18.949-7.966a4.4,4.4,0,0,1,2.989-1.19,4.36,4.36,0,0,1,2.944,1.148c5.188,5.043,11.209,7.573,18.994,8.007a7.164,7.164,0,0,1,6.755,7.01c.01.688.029,1.386.06,2.1.3,15.661.747,39.248-27.142,48.974a4.816,4.816,0,0,1-.659.179,4.845,4.845,0,0,1-.952.094A4.858,4.858,0,0,1-3232.13-3202.584Zm.693-63.347c-5.747,5.587-12.779,8.559-21.456,9.042a3.184,3.184,0,0,0-3.005,3.113c-.011.729-.031,1.466-.065,2.219-.309,16.182-.693,36.3,24.485,45.055a.891.891,0,0,0,.124.033.892.892,0,0,0,.176.018.867.867,0,0,0,.174-.017,1.009,1.009,0,0,0,.127-.034c25.174-8.78,24.791-28.922,24.483-45.107-.032-.7-.052-1.437-.063-2.166a3.183,3.183,0,0,0-3-3.113c-8.676-.483-15.71-3.455-21.5-9.084a.363.363,0,0,0-.215-.066A.376.376,0,0,0-3231.437-3265.932Zm-5.991,42.9a6.771,6.771,0,0,1-6.75-6.75v-13.5a6.77,6.77,0,0,1,6.75-6.75h13.5a6.77,6.77,0,0,1,6.75,6.75v13.5a6.771,6.771,0,0,1-6.75,6.75Zm-4.5-20.25v13.5a4.506,4.506,0,0,0,4.5,4.5h13.5a4.505,4.505,0,0,0,4.5-4.5v-13.5a4.5,4.5,0,0,0-4.5-4.5h-13.5A4.505,4.505,0,0,0-3241.928-3243.281Zm4.5,6.75a6.749,6.749,0,0,1,6.75-6.75,6.75,6.75,0,0,1,6.75,6.75,6.751,6.751,0,0,1-6.75,6.75A6.75,6.75,0,0,1-3237.429-3236.531Zm2.25,0a4.5,4.5,0,0,0,4.5,4.5,4.5,4.5,0,0,0,4.5-4.5,4.5,4.5,0,0,0-4.5-4.5A4.5,4.5,0,0,0-3235.179-3236.531Zm10.126-7.312a1.687,1.687,0,0,1,1.688-1.687,1.687,1.687,0,0,1,1.688,1.688,1.688,1.688,0,0,1-1.687,1.688A1.688,1.688,0,0,1-3225.053-3243.843Z"
      transform="translate(3260 3270.001)"
      fill="url(#linear-gradient8)"
    />
  </svg>
);

export default InstagramGuide;
