import { t } from "i18next";
import { routeNames } from "../../router/routes/routeNames";
import PlaceHolderFeatured1 from "../blurred/payment/featured-1.webp";
import PlaceHolderFeatured2 from "../blurred/payment/featured-2.webp";
import PlaceHolderFeatured3 from "../blurred/payment/featured-3.webp";
import PlaceHolderFeatured4 from "../blurred/payment/featured-4.webp";
import PlaceHolderFeatured5 from "../blurred/payment/featured-5.webp";
import { LegalIcon, SettingIcon, SupportIcon } from "../svgs/navbar";
import {
  AssistantIcon,
  AudienceIcon,
  EngageIcon,
  FollowersIcon,
  SteadyGrowthIcon,
  TargetIcon,
} from "../svgs/payment";
import { aws_images, aws_videos } from "./images";

export const support_email = "support@ask-me.app";

export const featuredVideos = [
  {
    name: "Angela Chen",
    job: "Instagram Influencer",
    thumbnail: aws_images.featured1,
    profile: aws_images.featuredMask1,
    placeHolder: PlaceHolderFeatured1,
    video: aws_videos.testimonial1,
  },
  {
    name: "Jake Pharell",
    job: "Food Blogger",
    thumbnail: aws_images.featured2,
    profile: aws_images.featuredMask2,
    placeHolder: PlaceHolderFeatured2,
    video: aws_videos.testimonial2,
  },
  {
    name: "Madison Hildreth",
    job: "Fitness Coach",
    thumbnail: aws_images.featured3,
    profile: aws_images.featuredMask3,
    placeHolder: PlaceHolderFeatured3,
    video: aws_videos.testimonial3,
  },
  {
    name: "David Taylor",
    job: "E-commerce owner",
    thumbnail: aws_images.featured4,
    profile: aws_images.featuredMask4,
    placeHolder: PlaceHolderFeatured4,
    video: aws_videos.testimonial4,
  },
  {
    name: "Savannah Ann",
    job: "Singer",
    thumbnail: aws_images.featured5,
    profile: aws_images.featuredMask5,
    placeHolder: PlaceHolderFeatured5,
    video: aws_videos.testimonial5,
  },
];

export const best_choice_reasons = [
  {
    icon: AssistantIcon,
    title: t("Live Strategy Assistance"),
    content: t(
      "We take the time to understand your brand, audience, and objectives, crafting a growth plan tailored to your unique needs. This hands-on approach ensures every step of your journey is optimized for success."
    ),
  },
  {
    icon: TargetIcon,
    title: t("AI Audience Targeting"),
    content: t(
      "Using advanced AI algorithms, AskMe pinpoints the exact audience that will resonate with your content. Our strategic engagement fosters authentic connections, driving consistent and meaningful growth."
    ),
  },
  {
    icon: SteadyGrowthIcon,
    title: t("Steady and Reliable Growth Results"),
    content: t(
      "By collaborating with AskMe, your Instagram evolves into a powerful platform - whether you’re seeking brand visibility, influencer reach, or sales generation. Real growth, every step of the way."
    ),
  },
];

export const expectations = [
  [t("Engaged followers"), t("Brand Partnerships")],
  [t("Full flexibility"), t("Perfect Life Balance")],
];

export const working_process = [
  {
    icon: AudienceIcon,
    title: t("1. You Define Your Audience"),
    content: t(
      "Share details about the followers you want: age, location, interests, and more."
    ),
  },
  {
    icon: EngageIcon,
    title: t("2. We Engage the Right People"),
    content: t(
      "AskMe promotes your content to your ideal Instagram audience through organic strategies."
    ),
  },
  {
    icon: FollowersIcon,
    title: t("3. You get quality followers"),
    content: t(
      "You build a true community of real, engaged Instagram followers who genuinely love your content."
    ),
  },
];

export const faqs = [
  {
    title: t("Is my Instagram account safe?"),
    content: t(
      "Absolutely. Unlike other services, we don’t use bots, automation, or require your login credentials. Your account’s security is our top priority. AskMe’s methods are 100% organic."
    ),
  },
  {
    title: t("How does AskMe stand out"),
    content: t(
      "We offer unmatched affordability and personalized service, backed by round-the-clock live chat support, making us the go-to choice for organic growth."
    ),
  },
  {
    title: t("Do you use bots or fake accounts?"),
    content: t(
      "Never. We prioritize organic strategies like influencer collaborations and targeted outreach."
    ),
  },
  {
    title: t("What happens if I cancel my subscription?"),
    content: t("Your followers remain yours, even after cancellation."),
  },
  {
    title: t("When will I see results?"),
    content: t(
      "Results are often visible within 24 hours, with maximum impact achieved within 7 days."
    ),
  },
  {
    title: t("Are the followers real?"),
    content: t(
      "Yes. All followers gained through AskMe are genuine and organically attracted to your content."
    ),
  },
  {
    title: t("Who benefits from AskMe?"),
    content: t(
      "Whether you’re a business, influencer, or someone growing a personal Instagram account, AskMe delivers effective results for all."
    ),
  },
  {
    title: t("What types of accounts are eligible?"),
    content: t(
      "AskMe works with personal, business, and influencer accounts of all sizes."
    ),
  },
  {
    title: t("What’s required on my end?"),
    content: t(
      "All you need is an Instagram account. You can even manage multiple profiles with AskMe."
    ),
  },
  {
    title: t("How many followers can I gain?"),
    content: t(
      "Our clients typically grow by at least 1,000 followers per month through authentic engagement techniques."
    ),
  },
];

export const menu_items = [
  {
    icon: SupportIcon,
    label: t("Support"),
    path: `mailto:${support_email}`,
  },
  {
    icon: LegalIcon,
    label: t("Terms of Service"),
    path: routeNames.terms,
  },
  {
    icon: LegalIcon,
    label: t("Privacy Policy"),
    path: routeNames.privacy,
  },
  {
    icon: SettingIcon,
    label: t("Manage Subscriptions"),
    path: routeNames.subscription,
  },
];
