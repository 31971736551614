import React from "react";
import { I18nextProvider } from "react-i18next";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import i18next from "../i18/i18next";
import MobileView from "../layout/MobileView";
import Navbar from "../layout/Navbar";
import { routeNames } from "./routes/routeNames";
import { commonRoutes, protectedRoutes, publicRoutes } from "./routes/routes";

const Container = () => {
  const { isLoggedIn } = useSelector((state) => state.authState);
  // const isLoggedIn = true;

  return (
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <Routes>
          <Route element={<MobileView />}>
            {isLoggedIn ? (
              <Route element={<Navbar />}>
                {protectedRoutes.map((route, _) => (
                  <Route key={_} path={route.path} element={<route.page />} />
                ))}
              </Route>
            ) : (
              publicRoutes.map((route, _) => (
                <Route key={_} path={route.path} element={<route.page />} />
              ))
            )}
            {commonRoutes.map((route, _) => (
              <Route key={_} path={route.path} element={<route.page />} />
            ))}
          </Route>
          <Route path="*" element={<Navigate to={routeNames.landing} />} />
        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  );
};

export default Container;
