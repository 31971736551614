import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import "../../../assets/styles/pages/forgot.css";
import { LogoIcon } from "../../../assets/svgs/common";
import Button from "../../../components/common/Button";
import Loader from "../../../components/common/Loader";
import { routeNames } from "../../../router/routes/routeNames";

const Forgot = () => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState({
    touched: false,
    message: "",
  });
  const [loading, setLoading] = React.useState(false);

  const handleEmail = (e) => {
    setEmail(e.target.value);
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const error = !emailRegex.test(e.target.value) ? t("Invalid Email") : "";
    setError((pre) => ({ ...pre, touched: true, message: error }));
  };

  const handleForgot = () => {
    if (error.message) {
      return;
    }

    if (!error.touched) {
      setError((pre) => ({
        ...pre,
        touched: true,
        message: t("Email is required"),
      }));
      return;
    }
  };

  return (
    <div className="grad-lavender-white-pink-v min-vh-100 d-flex flex-column justify-content-around common-padding-y common-padding-x">
      <div className="d-flex flex-column justify-content-center">
        <div className="d-flex justify-content-center">
          <LogoIcon />
        </div>
        <div className="forgot-header">
          <p className="text-center text-uppercase grad-text-blue-pink fs-24 open-sans-800 m-0">
            {t("ASKME ACCOUNT")}
          </p>

          <p className="text-center text-black fs-14 fw-bold m-0 w-75">
            <Trans
              values={{ email: email }}
              i18nKey={"Enter your registered email to get your username"}
              components={{ br: <br /> }}
            />
          </p>
        </div>
        <div className="forgot-input-wrapper">
          <input
            onChange={handleEmail}
            value={email}
            placeholder="Email"
            type="email"
            className="forgot-input"
          />
          <span className="forgot-error-text fs-10">{error?.message}</span>
        </div>
        {loading ? (
          <div className="w-100 d-flex justify-content-center">
            <Loader />
          </div>
        ) : (
          <Button label={"Submit"} onClick={handleForgot} />
        )}
        <div className="d-flex justify-content-center common-padding-y">
          <Link
            to={routeNames.login}
            className="resent-button fs-14 fw-bold text-decoration-underline bg-transparent text-center"
          >
            {t("Existing user login")}
          </Link>
        </div>
      </div>
      <p className="fs-14 fw-regular text-black text-center">
        {t("Copyright ® 2025. All Rights Reserved.")}
      </p>
    </div>
  );
};

export default Forgot;
