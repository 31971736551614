import { useRecurly } from "@recurly/react-recurly";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import {
  createSubscription,
  getPrice,
  getRecurlyToken,
  loginUser,
} from "../../services/api/api";
import { generateRandomCode } from "../../utils/utils";
import Button from "../common/Button";
import Loader from "../common/Loader";
// import { loginUser } from "../../redux/slices/auth";
import { useNavigate } from "react-router-dom";
import { routeNames } from "../../router/routes/routeNames";
import { showToast } from "../../utils/toast";

const applePayOptions = {
  requiredBillingAddressFields: ["all"],
  requiredShippingContactFields: ["phone", "email", "postalAddress"],
  countryCode: "US",
};

const plan_code = "weeklyplan";

const Input = ({ label, inputClass, inputRef, error, ...props }) => (
  <div className="d-flex flex-column position-relative">
    <label
      className="payment-details-label fs-14 text-black fw-regular"
      htmlFor={label + "label"}
    >
      {label}
    </label>
    <input
      ref={inputRef}
      className={`payment-details-input ${inputClass || ""} ${
        error ? "card-details-error" : ""
      }`}
      name={label + "label"}
      {...props}
    />
    <span className="payment-input-error position-absolute bottom-0 left-0 fs-10">
      {error}
    </span>
  </div>
);

const ExpiryInput = ({
  handleCardMonthChange,
  handleCardYearChange,
  cardMonthRef,
  cardYearRef,
  monthError,
  yearError,
  monthValue,
  yearValue,
}) => (
  <div className="d-flex flex-column position-relative">
    <label
      className="payment-details-label fs-14 text-black fw-regular"
      htmlFor="cardMonth"
    >
      {t("Expires on")}
    </label>
    <div className="d-flex justify-content-center gap-2 pe-4">
      <input
        className={`payment-details-input w-100 text-center ${
          monthError ? "card-details-error" : ""
        }`}
        placeholder="MM"
        inputMode="numeric"
        autoComplete="off"
        id="cardMonth"
        name="cardMonth"
        type="text"
        data-recurly={"month"}
        maxLength={2}
        onChange={handleCardMonthChange}
        ref={cardMonthRef}
        value={monthValue}
      />
      <input
        className={`payment-details-input w-100 text-center ${
          yearError ? "card-details-error" : ""
        }`}
        placeholder="YY"
        inputMode="numeric"
        autoComplete="off"
        id="cardYear"
        name="cardYear"
        type="text"
        maxLength={2}
        data-recurly={"year"}
        onChange={handleCardYearChange}
        ref={cardYearRef}
        value={yearValue}
      />
    </div>
    <span className="payment-input-error position-absolute bottom-0 left-0 fs-10">
      {monthError || yearError}
    </span>
  </div>
);

const PaymentMethods = ({ methodVisible, setMethodVisible }) => {
  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState({
    card: false,
    applePay: false,
  });
  const [appleAvailable, setAppleAvailable] = useState("");
  const [unitPrice, setUnitPrice] = useState(null);
  const [isSupport, setIsSupport] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [touchedDetails, setTouchedDetails] = useState({
    cardNumber: false,
    cardMonth: false,
    cardYear: false,
    cvc: false,
    userName: false,
    email: false,
  });
  const [detailsError, setDetailsError] = useState({
    cardNumber: t("Enter Card Number"),
    cardMonth: t("Enter Expiry Month"),
    cardYear: t("Enter Expiry Year"),
    cvc: t("Enter CVC"),
    userName: t("Enter Username"),
    email: t("Enter Email"),
  });
  const [details, setDetails] = useState({
    cardNumber: "",
    cardMonth: "",
    cardYear: "",
    cvc: "",
    email: "",
    userName: "",
  });

  const cardMonthRef = useRef();
  const cardYearRef = useRef();
  const cardCvvRef = useRef();
  const navigate = useNavigate();
  const recurly = useRecurly();
  const risk = recurly.Risk();
  const currentYear = new Date().getFullYear().toString().slice(2, 4);
  const nameString = details.email?.split("@")[0];

  const applePay = recurly.ApplePay({
    country: "US",
    currency: "USD",
    label: "Ask-Me",
    total: `${unitPrice}`,
  });

  // const applePay = null;

  const toggleMethodVisibility = () => {
    setMethodVisible(!methodVisible);
  };

  const handleError = (key, value) =>
    setDetailsError((pre) => ({ ...pre, [key]: value }));

  const checkError = (key) =>
    detailsError[key] && touchedDetails[key] ? detailsError[key] : "";

  const handleTouched = (key, value) =>
    setTouchedDetails((pre) => ({ ...pre, [key]: value }));

  const handleValue = (key, value) =>
    setDetails((pre) => ({ ...pre, [key]: value }));

  const divideStringIntoHalf = () => {
    const length = nameString?.length;
    const middle = Math.floor(length / 2);
    let result = {};

    result.firstName = nameString?.slice(0, middle);
    result.lastName = nameString?.slice(middle);

    return result;
  };

  const handleEmailChange = (e) => {
    handleValue("email", e.target.value);
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const error = !emailRegex.test(e.target.value) ? t("Invalid Email") : "";
    handleError("email", error);

    !touchedDetails.email && handleTouched("email", true);
  };

  const handleUsernameChange = (e) => {
    handleValue("userName", e.target.value);
    handleError("userName", !e.target.value ? t("Enter Username") : "");
    !touchedDetails.userName && handleTouched("userName", true);
  };

  const handleCardNumberChange = (e) => {
    const inputVal = e.target.value;
    const inputCardNumber = e.target.value.replace(/\D/g, "");
    const formattedCardNumber = inputCardNumber
      .replace(/(\d{4})/g, "$1 ")
      .trim();

    handleValue("cardNumber", formattedCardNumber);

    const checkVal = inputVal.split(" ").join("");
    const length = checkVal.length;
    const error =
      !inputVal || length < 15 || length > 16 ? t("Invalid Card Number") : "";
    handleError("cardNumber", error);

    if (length === 16) {
      cardMonthRef.current.focus({ preventScroll: true });
    }

    !touchedDetails.cardNumber && handleTouched("cardNumber", true);
  };

  const handleCardMonthChange = (e) => {
    const length = e.target.value.length;
    handleValue("cardMonth", e.target.value);
    const error = length < 2 || e.target.value > 12 ? t("Invalid Month") : "";
    handleError("cardMonth", error);

    if (length === 2 && e.target.value <= 12) {
      cardYearRef.current.focus({ preventScroll: true });
    }

    !touchedDetails.cardMonth && handleTouched("cardMonth", true);
  };

  const handleCardYearChange = (e) => {
    const length = e.target.value.length;
    handleValue("cardYear", e.target.value);
    const error =
      length < 2 || e.target.value < currentYear ? t("Invalid Year") : "";
    handleError("cardYear", error);

    if (length === 2 && e.target.value >= currentYear) {
      cardCvvRef.current.focus({ preventScroll: true });
    }

    !touchedDetails.cardYear && handleTouched("cardYear", true);
  };

  const handleCardCvvChange = (e) => {
    const length = e.target.value.length;
    handleValue("cvc", e.target.value);
    const error = length < 3 ? t("Invalid CVC") : "";
    handleError("cvc", error);
    !touchedDetails.cvc && handleTouched("cvc", true);
  };

  const fetchPaymentRequirements = async () => {
    const response = await getPrice();
    if (response.status === 200) {
      const priceList = response?.data?.data?.plan_list;
      const currentUnitPrice =
        priceList?.length > 0 &&
        priceList?.find((plans) => plans.plan_code === plan_code);
      setUnitPrice(currentUnitPrice?.unit_amount);
      //   setPaypalPlan(currentUnitPrice?.paypal_code);
      //   setCurrentPlan(currentUnitPrice?.plan_code);
    } else {
      setError("Error fetching payment requirements");
    }
    setLoading(false);
  };

  const sendData = async (token) => {
    setLoadingPayment((pre) => ({ ...pre, applePay: true }));
    const code = generateRandomCode();

    try {
      await createSubscription({
        email: details?.email?.toLowerCase(),
        token: token.id,
        code: code,
        instagram_username: details?.userName,
        plan_code: plan_code,
        payment_type: "recurly",
      })
        .then(async (response) => {
          if (response.status === 200) {
            await loginUser({
              email: details?.email?.toLowerCase(),
            })
              .then((res) => {
                if (res.status === 200) {
                  localStorage.setItem("email", details?.email);
                  showToast(res?.data?.message, "success");
                  navigate(routeNames.otp);
                  setLoadingPayment((pre) => ({ ...pre, applePay: false }));
                }
              })
              .catch((err) => {
                showToast(err?.response?.data, "error");
                setLoadingPayment((pre) => ({ ...pre, applePay: false }));
              });
          }
        })
        .catch((error) => {
          showToast(error?.response?.data, "error");
          setLoadingPayment((pre) => ({ ...pre, applePay: false }));
        });
    } catch (e) {
      setLoadingPayment((pre) => ({ ...pre, applePay: false }));
    }
    setPaymentProcessing(false);
    setLoadingPayment((pre) => ({ ...pre, applePay: false }));
  };

  const handleThreeDSPayment = async (payload) => {
    try {
      setLoadingPayment((pre) => ({ ...pre, card: true }));

      const response = await createSubscription(payload);
      if (response.status === 200) {
        await loginUser({
          email: details?.email?.toLowerCase(),
        })
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem("email", details.email.toLowerCase());
              showToast(res?.data?.message, "success");
              navigate(routeNames.otp);
              setLoadingPayment((pre) => ({ ...pre, card: false }));
            }
          })
          .catch((err) => {
            showToast(err?.response?.data, "error");
            setLoadingPayment((pre) => ({ ...pre, card: false }));
          });
      }
    } catch (error) {
      showToast(
        error?.response?.data?.error ??
          error?.response?.data?.message ??
          error?.response?.data ??
          "Something went wrong",
        "error"
      );
      setLoadingPayment((pre) => ({ ...pre, card: false }));
    }
  };

  const handleAppleClick = (event) => {
    event.preventDefault();
    if (!isSupport) {
      showToast(
        "Your device or browser is not compatible with Apple Pay.",
        "error"
      );
      setLoadingPayment((pre) => ({ ...pre, applePay: false }));
      return;
    } else if (isSupport && applePay && details.email && details.userName) {
      if (detailsError.email || detailsError.userName) {
        handleTouched("userName", true);
        handleTouched("email", true);
        return;
      }

      applePay?.ready(function () {
        applePay?.begin();
      });

      applePay.on("error", function (err) {
        console.error("Apple Pay error", err);
      });

      applePay.on("token", async function (token) {
        await sendData(token);
      });
    } else {
      if (!details.email || !details.userName) {
        showToast("Please enter your email and username", "error");
        handleTouched("userName", true);
        handleTouched("email", true);
      }
    }
  };

  const handleCardPayment = async (event) => {
    event.preventDefault();
    setLoadingPayment((pre) => ({ ...pre, card: true }));

    if (
      detailsError.cardNumber ||
      detailsError.cardMonth ||
      detailsError.cardYear ||
      detailsError.cvc ||
      detailsError.email ||
      detailsError.userName
    ) {
      setTouchedDetails({
        cardNumber: true,
        cardMonth: true,
        cardYear: true,
        cvc: true,
        userName: true,
        email: true,
      });
      setLoadingPayment((pre) => ({ ...pre, card: false }));
      return;
    }

    let showErr = false;
    if (!recurly && showErr) {
      setLoadingPayment((pre) => ({ ...pre, card: false }));
      return;
    }
    const { firstName, lastName } = divideStringIntoHalf();
    let formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("number", details.cardNumber);
    formData.append("month", details.cardMonth);
    formData.append("year", details.cardYear);
    formData.append("cvv", details.cvc);
    formData.append("email", details.email);
    formData.append("three_d_secure_action_required", true);
    formData.append("key", process.env.REACT_APP_RECURLY_KEY);

    await getRecurlyToken(formData)
      .then((response) => {
        if (
          !detailsError.cardNumber &&
          !detailsError.cardMonth &&
          !detailsError.cardYear &&
          !detailsError.cvc &&
          !detailsError.email &&
          !detailsError.userName &&
          !response.message
        ) {
          setLoadingPayment((pre) => ({ ...pre, card: true }));
          const code = generateRandomCode();
          const token = response?.data?.id;
          if (token) {
            try {
              createSubscription({
                email: details?.email?.toLowerCase(),
                token: token,
                instagram_username: details?.userName,
                code: code,
                plan_code: plan_code,
                payment_type: "recurly",
              })
                .then(async (response) => {
                  if (response.status === 201) {
                    setLoadingPayment((pre) => ({ ...pre, card: true }));
                    const threeDSecure = risk.ThreeDSecure({
                      actionTokenId: response?.data?.action_token_id,
                    });
                    threeDSecure.on("error", (err) => {
                      setLoadingPayment((pre) => ({ ...pre, card: false }));
                      console.log("threeDSecure err", err);
                      showToast("Payment Rejected", "error");
                    });

                    threeDSecure.on("token", (threeDsToken) => {
                      setLoadingPayment((pre) => ({ ...pre, card: true }));
                      handleThreeDSPayment({
                        email: details?.email?.toLowerCase(),
                        token: token,
                        plan_code: plan_code,
                        three_d_secure_action_result_token_id: threeDsToken?.id,
                        code: code,
                        accountCode: response?.data?.accountCode,
                        accountId: response?.data?.accountId,
                        payment_type: "recurly",
                      });
                    });

                    threeDSecure.attach(document.getElementById("3D-Secure"));
                  }
                  if (response.status === 200) {
                    await loginUser({
                      email: details?.email?.toLowerCase(),
                    })
                      .then((res) => {
                        if (res.status === 200) {
                          localStorage.setItem("email", details?.email);
                          showToast(res?.data?.message, "success");
                          navigate(routeNames.otp);
                          setLoadingPayment((pre) => ({ ...pre, card: false }));
                        }
                      })
                      .catch((err) => {
                        showToast(err?.response?.data, "error");
                        setLoadingPayment((pre) => ({ ...pre, card: false }));
                      });
                  }
                })
                .catch((error) => {
                  showToast(
                    error?.response?.data?.error ??
                      error?.response?.data?.message ??
                      error?.response?.data ??
                      "Something went wrong",
                    "error"
                  );
                  setLoadingPayment((pre) => ({ ...pre, card: false }));
                });
            } catch (e) {
              setLoadingPayment((pre) => ({ ...pre, card: false }));
              console.log(e.err);
            }
          }
        } else {
          if (
            response?.message ||
            (!detailsError.cardNumber &&
              !detailsError.cardYear &&
              !detailsError.cardMonth &&
              !detailsError.cvc)
          ) {
            showToast("Card number cannot be a test card number", "error");
          }
          setLoadingPayment((pre) => ({ ...pre, card: false }));
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    recurly.configure({
      applePay: applePayOptions,
    });
  }, [recurly]);

  useEffect(() => {
    if (!window.ApplePaySession) {
      if (appleAvailable !== "Not Available") {
        setIsSupport(false);
        setAppleAvailable("Not Available");
      }
      return;
    }
  }, [isPaymentProcessing]);

  useEffect(() => {
    fetchPaymentRequirements();
  }, []);

  return (
    <div className="payment-section-v">
      {methodVisible ? (
        loading ? (
          <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <Loader />
          </div>
        ) : error ? (
          <>
            <p className="fs-14 fw-bold price-error text-center">{error}</p>
          </>
        ) : (
          <div className="payment-methods-container">
            <div className="email-username-input d-flex flex-column">
              <Input
                label={t("Instagram Username")}
                onChange={handleUsernameChange}
                value={details.userName}
                type="text"
                error={checkError("userName")}
              />
              <Input
                label={t("Email")}
                onChange={handleEmailChange}
                value={details.email}
                type="email"
                error={checkError("email")}
              />
            </div>
            <div className="card-details d-flex flex-column">
              <Input
                onChange={handleCardNumberChange}
                label={t("Card Number")}
                placeholder="xxxx xxxx xxxx xxxx"
                id="cardNumber"
                type="text"
                data-recurly={"number"}
                inputMode="numeric"
                autoComplete="off"
                value={details.cardNumber}
                error={checkError("cardNumber")}
              />
              <div className="d-flex justify-content-between w-100">
                <div className="expiry-input-wrapper">
                  <ExpiryInput
                    handleCardMonthChange={handleCardMonthChange}
                    handleCardYearChange={handleCardYearChange}
                    cardMonthRef={cardMonthRef}
                    cardYearRef={cardYearRef}
                    monthValue={details.cardMonth}
                    yearValue={details.cardYear}
                    monthError={checkError("cardMonth")}
                    yearError={checkError("cardYear")}
                  />
                </div>
                <div className="cvc-input-wrapper">
                  <Input
                    label={t("CVC")}
                    placeholder="XXX"
                    onChange={handleCardCvvChange}
                    inputClass={"text-center"}
                    id="cardYear"
                    type="text"
                    data-recurly={"cvv"}
                    inputMode="numeric"
                    autoComplete="off"
                    maxLength={4}
                    inputRef={cardCvvRef}
                    value={details.cvc}
                    error={checkError("cvc")}
                  />
                  <input
                    type="hidden"
                    name="recurly-token"
                    data-recurly="token"
                  />
                </div>
              </div>
            </div>
            {loadingPayment?.card ? (
              <div className="w-100 d-flex justify-content-center py-3">
                <Loader />
              </div>
            ) : (
              <Button
                onClick={handleCardPayment}
                // onClick={login}
                label={t("Process Credit Card")}
                hideArrow
              />
            )}
            <p className="or-text text-black text-uppercase text-center fs-14 fw-regular">
              {t("OR")}
            </p>
            {loadingPayment?.applePay ? (
              <div className="w-100 d-flex justify-content-center py-3">
                <Loader />
              </div>
            ) : (
              <button
                className="payment-btn apple-pay-btn w-100"
                onClick={handleAppleClick}
                // onClick={login}
                // onClick={() => showToast("as")}
              >
                <ApplePayIcon />
              </button>
            )}
            <div
              id="3D-Secure"
              className="threeDSecure"
              style={{
                maxWidth: "390px",
              }}
            ></div>
          </div>
        )
      ) : (
        <Button
          label={t("Get My Plan")}
          className={"mb-1"}
          onClick={toggleMethodVisibility}
        />
      )}
    </div>
  );
};

const ApplePayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58.474"
    height="24"
    viewBox="0 0 58.474 24"
  >
    <path
      id="Icon_awesome-apple-pay"
      data-name="Icon awesome-apple-pay"
      d="M10.681,11.856A3.587,3.587,0,0,1,7.8,13.217a4.05,4.05,0,0,1,1.032-2.978,4.3,4.3,0,0,1,2.86-1.471,4.347,4.347,0,0,1-1.014,3.088m1,1.571c-1.59-.091-2.951.9-3.7.9-.767,0-1.919-.859-3.179-.831A4.7,4.7,0,0,0,.814,15.921C-.9,18.872.367,23.23,2.029,25.633c.813,1.188,1.781,2.494,3.06,2.448C6.3,28.035,6.78,27.3,8.242,27.3s1.9.786,3.179.767c1.325-.027,2.156-1.188,2.969-2.375a10.327,10.327,0,0,0,1.325-2.732,4.316,4.316,0,0,1-2.585-3.919,4.395,4.395,0,0,1,2.092-3.682,4.515,4.515,0,0,0-3.545-1.928m9.172-3.307V27.926h2.768V21.841h3.828a5.65,5.65,0,0,0,5.947-5.874,5.585,5.585,0,0,0-5.856-5.847H20.849Zm2.768,2.33h3.188c2.4,0,3.773,1.279,3.773,3.526s-1.37,3.545-3.782,3.545H23.617ZM38.436,28.063a4.561,4.561,0,0,0,4.075-2.275h.055v2.138h2.558V19.064c0-2.567-2.056-4.23-5.217-4.23-2.933,0-5.107,1.681-5.189,3.983h2.494A2.432,2.432,0,0,1,39.824,17c1.69,0,2.64.786,2.64,2.238v.987l-3.453.21c-3.207.192-4.942,1.507-4.942,3.791C34.078,26.528,35.868,28.063,38.436,28.063Zm.749-2.11c-1.471,0-2.412-.713-2.412-1.791,0-1.124.9-1.772,2.631-1.873l3.07-.192v1a3,3,0,0,1-3.289,2.85Zm9.364,6.815c2.7,0,3.965-1.032,5.07-4.148L58.47,15.008H55.657L52.4,25.523h-.055L49.1,15.008H46.21l4.678,12.964-.256.786a2.2,2.2,0,0,1-2.33,1.855c-.219,0-.64-.027-.813-.046V32.7A9.576,9.576,0,0,0,48.549,32.768Z"
      transform="translate(0.004 -8.768)"
      fill="#fff"
    />
  </svg>
);

export default PaymentMethods;
