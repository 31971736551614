import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "./assets/styles/common.css";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import Container from "./router/Container";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer style={{ paddingInline: 30 }} />
      <Container />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
