import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { support_email } from "../../assets/content/data";
import { routeNames } from "../../router/routes/routeNames";

const CustomLink = ({ to, label }) => (
  <Link to={to} className="fs-14 fw-light text-black">
    {label}
  </Link>
);

const Footer = () => {
  return (
    <div className="w-100 text-center footer-wrapper d-flex flex-column align-items-center justify-content-center gap-2">
      <div className="footer-line w-100"></div>
      <CustomLink to={routeNames.terms} label={t("Terms and Conditions")} />
      <CustomLink to={routeNames.privacy} label={t("Privacy Policy")} />
      <CustomLink to={`mailto:${support_email}`} label={support_email} />
      <p className="fs-14 fw-light text-black">
        {t("Copyright ® 2025. All Rights Reserved.")}
      </p>
    </div>
  );
};

export default Footer;
