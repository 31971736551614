import { Elements, RecurlyProvider } from "@recurly/react-recurly";
import React, { useRef, useState } from "react";
import "../../../assets/styles/pages/payment.css";
import Footer from "../../../components/common/Footer";
import CoveredPoints from "../../../components/payment/CoveredPoints";
import Faqs from "../../../components/payment/Faqs";
import FeaturedIn from "../../../components/payment/FeaturedIn";
import GrowthPath from "../../../components/payment/GrowthPath";
import InstagramGuide from "../../../components/payment/InstagramGuide";
import PaymentMethods from "../../../components/payment/PaymentMethods";
import Plan from "../../../components/payment/Plan";
import StartGrowing from "../../../components/payment/StartGrowing";
import Timer from "../../../components/payment/Timer";

const Payment = () => {
  const [methodVisible, setMethodVisible] = useState(false);
  const planBtnRef = useRef(null);

  const scrollToPlan = () => {
    planBtnRef.current?.scrollIntoView({ behavior: "smooth" });
    // setMethodVisible(true);
  };

  return (
    <div>
      <Timer />
      <StartGrowing scrollToPlan={scrollToPlan} />
      <FeaturedIn />
      <GrowthPath scrollToPlan={scrollToPlan} />
      <CoveredPoints />
      <InstagramGuide scrollToPlan={scrollToPlan} />
      <div
        ref={planBtnRef}
        className="covered-points-container payment-section-pv payment-padding-x"
      >
        <Plan />
        <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_KEY}>
          <Elements>
            <PaymentMethods
              setMethodVisible={setMethodVisible}
              methodVisible={methodVisible}
            />
          </Elements>
        </RecurlyProvider>
        <Faqs />
        <Footer />
      </div>
    </div>
  );
};

export default Payment;
