import { toast } from "react-toastify";

const time = 5000;

export const showToast = (message, type = "success") => {
  if (type === "success") {
    toast.success(message, {
      position: "bottom-center",
      autoClose: time,
      theme: "light",
      progress: undefined,
    });
  } else {
    toast.error(message, {
      position: "bottom-center",
      autoClose: time,
      theme: "light",
      progress: undefined,
    });
  }
};
