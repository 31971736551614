import React from "react";

const ImageLoader = ({ placeHolderImage, src, is, ...props }) => {
  const [imageSrc, setImageSrc] = React.useState(placeHolderImage || src);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    setIsLoaded(false);

    if (placeHolderImage) {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImageSrc(src);
        setIsLoaded(true);
      };

      img.onerror = (e) => {
        console.log(src, placeHolderImage, e);
      };
    } else {
      setIsLoaded(true);
    }
  }, [src]);

  return (
    <img
      src={imageSrc}
      fetchPriority="high"
      {...props}
      className={`img-fade ${props.className} ${isLoaded ? "" : ""}`}
    />
  );
};

export default ImageLoader;
