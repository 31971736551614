import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import "../assets/styles/layout/mobileView.css";
import { useSelector } from "react-redux";

const MobileView = () => {
  const location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.authState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location, isLoggedIn]);

  return (
    <div className="mobile-view">
      <Outlet />
    </div>
  );
};

export default MobileView;
