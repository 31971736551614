export const en = {
  //Navbar Starts here
  "Log Out": "Log Out",
  "All account data of @account_name will be deleted. Do you really want to log out?":
    "All account data of @{{account_name}} will be deleted. Do you really want to log out?",
  Cancel: "Cancel",
  Support: "Support",
  "Terms of Service": "Terms of Service",
  "Privacy Policy": "Privacy Policy",
  "Manage Subscriptions": "Manage Subscriptions",
  Logout: "Logout",
  // Subscription starts here
  Active: "Active",
  "Do you want to cancel? Reach our friendly support team here":
    "Do you want to cancel?<br/>Reach our friendly support team <tag>here</tag>",
  // Footer starts here
  "Terms and Conditions": "Terms and Conditions",
  "Privacy Policy": "Privacy Policy",
  "Copyright ® 2025. All Rights Reserved.":
    "Copyright ® 2025. All Rights Reserved.",
  // payment screen starts here
  "90% OFF": "90% OFF",
  "FLASH SALE": "FLASH SALE",
  "Real, organic social": "Real, organic social",
  media: "media",
  success: "success",
  fans: "fans",
  growth: "growth",
  engagement: "engagement",
  "Forget automated bots and generic tools. AskMe ensures authentic growth by connecting you with your audience through AI-driven precision.":
    "Forget automated bots and generic tools. AskMe ensures authentic growth by connecting you with your audience through AI-driven precision.",
  "Start Growing Today": "Start Growing Today",
  "Limited-Time Offer": "Limited-Time Offer",
  "FLASH SALE": "FLASH SALE",
  "FEATURED IN": "FEATURED IN",
  "The proven path to organic growth": "The proven path<tag/>to organic growth",
  "Trusted by 17,000+ Influencers and Brands":
    "Trusted by 17,000+<tag/>Influencers and Brands",
  "Experience safe and sustainable media growth with AskMe's authentic approach.":
    "Experience safe and sustainable media growth with AskMe's authentic approach.",
  "Get Started Now": "Get Started Now",
  "What Makes AskMe the Best Choice?": "What Makes AskMe<tag/>the Best Choice?",
  "Live Strategy Assistance": "Live Strategy Assistance",
  "We take the time to understand your brand, audience, and objectives, crafting a growth plan tailored to your unique needs. This hands-on approach ensures every step of your journey is optimized for success.":
    "We take the time to understand your brand, audience, and objectives, crafting a growth plan tailored to your unique needs. This hands-on approach ensures every step of your journey is optimized for success.",
  "AI Audience Targeting": "AI Audience Targeting",
  "Using advanced AI algorithms, AskMe pinpoints the exact audience that will resonate with your content. Our strategic engagement fosters authentic connections, driving consistent and meaningful growth.":
    "Using advanced AI algorithms, AskMe pinpoints the exact audience that will resonate with your content. Our strategic engagement fosters authentic connections, driving consistent and meaningful growth.",
  "Steady and Reliable Growth Results": "Steady and Reliable Growth Results",
  "By collaborating with AskMe, your Instagram evolves into a powerful platform - whether you’re seeking brand visibility, influencer reach, or sales generation. Real growth, every step of the way.":
    "By collaborating with AskMe, your Instagram evolves into a powerful platform - whether you’re seeking brand visibility, influencer reach, or sales generation. Real growth, every step of the way.",
  "Take the First Step": "Take the First Step",
  "Struggling with Instagram Growth? Join AskMe, We've Got You Covered!":
    "Struggling with Instagram Growth? Join AskMe, We've Got You Covered!",
  "Imagine waking up every day excited about the work you do. With AskMe, you can take control of your career and carve out a path that’s as flexible as it is fulfilling. Whether you’re building a personal brand, growing a business, or pursuing influencer opportunities, the possibilities are endless. AskMe is designed to help you create an Instagram presence that attracts the audience you’ve always dreamed of. From loyal followers to collaborations with top brands, we’ll guide you every step of the way to achieve your goals. Here’s what you can expect when you partner with AskMe:":
    "Imagine waking up every day excited about the work you do. With AskMe, you can take control of your career and carve out a path that’s as flexible as it is fulfilling. Whether you’re building a personal brand, growing a business, or pursuing influencer opportunities, the possibilities are endless.<tag/><tag/>AskMe is designed to help you create an Instagram presence that attracts the audience you’ve always dreamed of. From loyal followers to collaborations with top brands, we’ll guide you every step of the way to achieve your goals.<tag/><tag/>Here’s what you can expect when you partner with AskMe:",
  "Engaged followers": "Engaged followers",
  "Brand Partnerships": "Brand Partnerships",
  "Full flexibility": "Full flexibility",
  "Perfect Life Balance": "Perfect Life Balance",
  "And so much more...": "And so much more...",
  "How it Works": "How it Works",
  "The 3-Step Strategy Process": "The 3-Step Strategy Process",
  "1. You Define Your Audience": "1. You Define Your Audience",
  "Share details about the followers you want: age, location, interests, and more.":
    "Share details about the followers you want: age, location, interests, and more.",
  "2. We Engage the Right People": "2. We Engage the Right People",
  "AskMe promotes your content to your ideal Instagram audience through organic strategies.":
    "AskMe promotes your content to your ideal Instagram audience through organic strategies.",
  "3. You get quality followers": "3. You get quality followers",
  "You build a true community of real, engaged Instagram followers who genuinely love your content.":
    "You build a true community of real, engaged Instagram followers who genuinely love your content.",
  "Your Instagram Account Is Safe: No login credentials are required.":
    "Your Instagram Account Is Safe: No login credentials are required.",
  "At AskMe, we ensure that our influencer plans fully comply with Instagram's TOS. Unlike many other services that require you to share your account login to provide social growth, working with us guarantees that your Instagram account remains completely secure.":
    "At AskMe, we ensure that our influencer plans fully comply with Instagram's TOS. Unlike many other services that require you to share your account login to provide social growth, working with us guarantees that your Instagram account remains completely secure.",
  "Get 1-2K Followers per Month!": "Get 1-2K Followers per Month!",
  "7-Day Trial": "7-Day Trial",
  "Total Today": "Total Today",
  "$9.99": "$9.99",
  "$1.00": "$1.00",
  "Get My Plan": "Get My Plan",
  "Instagram Username": "Instagram Username",
  Email: "Email",
  "Card Number": "Card Number",
  "Expires on": "Expires on",
  CVC: "CVC",
  "Process Credit Card": "Process Credit Card",
  OR: "OR",
  "FAQs: We’re Here to Help": "FAQs: We’re Here to Help",
  "Still unsure? Reach out to us via email or live chat, and we’ll answer all your questions!":
    "Still unsure? Reach out to us via email or live chat, and we’ll answer all your questions!",
  "Is my Instagram account safe?": "Is my Instagram account safe?",
  "Absolutely. Unlike other services, we don’t use bots, automation, or require your login credentials. Your account’s security is our top priority. AskMe’s methods are 100% organic.":
    "Absolutely. Unlike other services, we don’t use bots, automation, or require your login credentials. Your account’s security is our top priority. AskMe’s methods are 100% organic.",
  "How does AskMe stand out": "How does AskMe stand out",
  "We offer unmatched affordability and personalized service, backed by round-the-clock live chat support, making us the go-to choice for organic growth.":
    "We offer unmatched affordability and personalized service, backed by round-the-clock live chat support, making us the go-to choice for organic growth",
  "Do you use bots or fake accounts?": "Do you use bots or fake accounts?",
  "Never. We prioritize organic strategies like influencer collaborations and targeted outreach.":
    "Never. We prioritize organic strategies like influencer collaborations and targeted outreach",
  "What happens if I cancel my subscription?":
    "What happens if I cancel my subscription?",
  "Your followers remain yours, even after cancellation.":
    "Your followers remain yours, even after cancellation",
  "When will I see results?": "When will I see results?",
  "Results are often visible within 24 hours, with maximum impact achieved within 7 days.":
    "Results are often visible within 24 hours, with maximum impact achieved within 7 days",
  "Are the followers real?": "Are the followers real?",
  "Yes. All followers gained through AskMe are genuine and organically attracted to your content.":
    "Yes. All followers gained through AskMe are genuine and organically attracted to your content",
  "Who benefits from AskMe?": "Who benefits from AskMe?",
  "Whether you’re a business, influencer, or someone growing a personal Instagram account, AskMe delivers effective results for all.":
    "Whether you’re a business, influencer, or someone growing a personal Instagram account, AskMe delivers effective results for all",
  "What types of accounts are eligible?":
    "What types of accounts are eligible?",
  "AskMe works with personal, business, and influencer accounts of all sizes.":
    "AskMe works with personal, business, and influencer accounts of all sizes.",
  "What’s required on my end?": "What’s required on my end?",
  "All you need is an Instagram account. You can even manage multiple profiles with AskMe.":
    "All you need is an Instagram account. You can even manage multiple profiles with AskMe.",
  "How many followers can I gain?": "How many followers can I gain?",
  "Our clients typically grow by at least 1,000 followers per month through authentic engagement techniques.":
    "Our clients typically grow by at least 1,000 followers per month through authentic engagement techniques.",
  // otp screen starts here
  "ENTER OTP": "ENTER OTP",
  "A one-time password has been sent to your Email:":
    "A one-time password has been<br/>sent to your Email: {{email}}",
  "Resend OTP": "Resend OTP",
  // activity screen starts here
  Followers: "Followers",
  Following: "Following",
  "7-Day Activity": "7-Day Activity",
  "30-Day Activity": "30-Day Activity",
  "Account Settings": "Account Settings",
  "Ensure your account is set to PUBLIC":
    "Ensure your account is set to <tag>PUBLIC</tag>",
  Important: "Important",
  "Please make sure to TURN OFF the 'Flag for Review' option. You can find it in the Follower and Invite Friends settings in the Instagram app. This will help ensure everything runs smoothly.":
    "Please make sure to <tag>TURN OFF the 'Flag for Review'</tag> option. You can find it in the Follower and Invite Friends settings in the Instagram app. This will help ensure everything runs smoothly.",
  //login screen starts here
  "ASKME ACCOUNT": "ASKME ACCOUNT",
  "Enter your registered email address to get full data analysis":
    "Enter your registered email address to get full data analysis",
  "Get My Analytics": "Get My Analytics",
  "Forgotten your account name?": "Forgotten your account name?",
  //forgot screen starts here
  "Enter your registered email to get your username":
    "Enter your registered email to get your username",
  "Existing user login": "Existing user login",
  //error texts
  "Enter Card Number": "Enter Card Number",
  "Enter Expiry Month": "Enter Expiry Month",
  "Enter Expiry Year": "Enter Expiry Year",
  "Enter CVC": "Enter CVC",
  "Enter Username": "Enter Username",
  "Enter Email": "Enter Email",
  "Invalid Email": "Invalid Email",
  "Invalid Card Number": "Invalid Card Number",
  "Invalid Month": "Invalid Month",
  "Invalid Year": "Invalid Year",
  "Invalid CVC": "Invalid CVC",
  "Email is required": "Email is required",
};
