import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { best_choice_reasons } from "../../assets/content/data";
import Button from "../common/Button";

const GrowthPath = ({ scrollToPlan }) => {
  return (
    <div className="payment-section-v payment-padding-x text-center">
      <p className="text-uppercase grad-text-blue-pink fs-24 lh-28 open-sans-800 payment-section-b">
        <Trans
          i18nKey={"The proven path to organic growth"}
          components={{ tag: <br /> }}
        />
      </p>
      <div className="trust-wrapper text-black payment-section-b">
        <p className="fs-20 open-sans-800 mb-0">
          <Trans
            i18nKey={"Trusted by 17,000+ Influencers and Brands"}
            components={{ tag: <br /> }}
          />
        </p>
        <p className="fs-14 fw-regular brands-text-m">
          {t(
            "Experience safe and sustainable media growth with AskMe's authentic approach."
          )}
        </p>
        <Button onClick={scrollToPlan} label={t("Get Started Now")} />
      </div>
      <p className="text-uppercase fs-20 open-sans-800 text-black payment-section-b">
        <Trans
          i18nKey={"What Makes AskMe the Best Choice?"}
          components={{ tag: <br /> }}
        />
      </p>
      {best_choice_reasons.map((choice, _) => (
        <div key={_} className="payment-section-b">
          <div className="mb-3 d-flex justify-content-center">
            <choice.icon />
          </div>
          <p className="fs-20 text-black open-sans-800">{choice.title}</p>
          <p className="fs-14 text-black lh-20">{choice.content}</p>
        </div>
      ))}
      <Button onClick={scrollToPlan} label={t("Take the First Step")} />
    </div>
  );
};

export default GrowthPath;
