import { createSlice } from "@reduxjs/toolkit";
import { getActivityData } from "../../services/api/api";

const initialState = {
  loading: true,
  data: {},
  error: null,
};

export const loadData = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await getActivityData();
    dispatch(setData(response.data.data));
    dispatch(setError(null));
  } catch (error) {
    console.error(error);
    dispatch(setError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const activitySlice = createSlice({
  name: "activityState",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    clearData: (state) => {
      state.data = {};
    },
  },
});

export const { setLoading, setData, setError, clearData } =
  activitySlice.actions;

export default activitySlice.reducer;
