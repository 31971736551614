import { t } from "i18next";

export const terms_data = {
  listOne: [
    {
      id: 1,
      data: t("terms_and_conditions.definitions.definitions_list.account"),
    },
    {
      id: 2,
      data: t("terms_and_conditions.definitions.definitions_list.company"),
    },
    {
      id: 3,
      data: t("terms_and_conditions.definitions.definitions_list.content"),
    },
    {
      id: 4,
      data: t("terms_and_conditions.definitions.definitions_list.device"),
    },
    {
      id: 5,
      data: t("terms_and_conditions.definitions.definitions_list.eula"),
    },
    {
      id: 6,
      data: t(
        "terms_and_conditions.definitions.definitions_list.intellectual_property_rights"
      ),
    },
    {
      id: 7,
      data: t("terms_and_conditions.definitions.definitions_list.subscription"),
    },
    {
      id: 8,
      data: t(
        "terms_and_conditions.definitions.definitions_list.user_generated_content"
      ),
    },
    {
      id: 9,
      data: t("terms_and_conditions.definitions.definitions_list.you"),
    },
  ],

  listTwo: [
    {
      id: 1,
      data: t("terms_and_conditions.changes_of_eula.list.changes_intro"),
    },
    {
      id: 2,
      data: t("terms_and_conditions.changes_of_eula.list.changes_effective"),
    },
  ],

  listThree: [
    {
      id: 1,
      data: t("terms_and_conditions.device.list.use_non_owned_device"),
    },
    {
      id: 2,
      data: t("terms_and_conditions.device.list.access_to_non_owned_device"),
    },
    {
      id: 3,
      data: t("terms_and_conditions.device.list.responsibility"),
    },
    {
      id: 4,
      data: t("terms_and_conditions.device.list.removal"),
    },
  ],

  listFour: [
    {
      id: 1,
      data: t("terms_and_conditions.capacity_and_age.list.over_16"),
    },
    {
      id: 2,
      data: t("terms_and_conditions.capacity_and_age.list.between_16_18"),
    },
  ],

  listFive: [
    {
      id: 1,
      data: t(
        "terms_and_conditions.intellectual_property.list.license_grant_and_webapp_use_terms"
      ),
    },
    {
      id: 2,
      data: t(
        "terms_and_conditions.intellectual_property.list.intellectual_property_rights_and_reservation_of_rights"
      ),
    },
    {
      id: 3,
      data: t(
        "terms_and_conditions.intellectual_property.list.restrictions_on_transferring_rights_to_webapp"
      ),
    },
    {
      id: 4,
      data: t(
        "terms_and_conditions.intellectual_property.list.restrictions_on_network_distribution_and_access"
      ),
    },
    {
      id: 5,
      data: t(
        "terms_and_conditions.intellectual_property.list.prohibitions_on_interfering_with_webapp_functionality"
      ),
    },
    {
      id: 6,
      data: t(
        "terms_and_conditions.intellectual_property.list.prohibitions_on_decompiling_and_reverse_engineering"
      ),
    },
    {
      id: 7,
      data: t(
        "terms_and_conditions.intellectual_property.list.prohibitions_on_unlawful_use_and_malicious_activity"
      ),
    },
  ],

  listSix: [
    {
      id: 1,
      data: t(
        "terms_and_conditions.subscription_and_trial_period.list.subscription_basis_and_fee_variation"
      ),
    },
    {
      id: 2,
      data: t(
        "terms_and_conditions.subscription_and_trial_period.list.automatic_renewal_and_account_charge"
      ),
    },
    {
      id: 3,
      data: t(
        "terms_and_conditions.subscription_and_trial_period.list.cancellation_of_subscription_renewal"
      ),
    },
    {
      id: 4,
      data: t(
        "terms_and_conditions.subscription_and_trial_period.list.manual_cancellation_required"
      ),
    },
    {
      id: 5,
      data: t(
        "terms_and_conditions.subscription_and_trial_period.list.free_trial_period_and_auto_conversion"
      ),
    },
  ],

  listSeven: [
    {
      id: 1,
      data: t(
        "terms_and_conditions.fees_and_purchase_terms.list.subscription_costs_availability"
      ),
    },
    {
      id: 2,
      data: t(
        "terms_and_conditions.fees_and_purchase_terms.list.payment_processing_by_platform_administrator"
      ),
    },
    {
      id: 3,
      data: t(
        "terms_and_conditions.fees_and_purchase_terms.list.no_access_to_payment_information"
      ),
    },
    {
      id: 4,
      data: t(
        "terms_and_conditions.fees_and_purchase_terms.list.questions_on_payment_processing"
      ),
    },
    {
      id: 5,
      data: t(
        "terms_and_conditions.fees_and_purchase_terms.list.account_charge_timing"
      ),
    },
    {
      id: 6,
      data: t(
        "terms_and_conditions.fees_and_purchase_terms.list.no_refunds_and_immediate_content_access"
      ),
    },
  ],

  listEight: [
    {
      id: 1,
      data: t(
        "terms_and_conditions.user_generated_content.list.exposure_to_objectionable_content"
      ),
    },
    {
      id: 2,
      data: t(
        "terms_and_conditions.user_generated_content.list.modifications_and_censorship"
      ),
    },
    {
      id: 3,
      data: t(
        "terms_and_conditions.user_generated_content.list.handling_objectionable_content"
      ),
    },
    {
      id: 4,
      data: t(
        "terms_and_conditions.user_generated_content.list.company_disclaimer"
      ),
    },
  ],

  listNine: [
    {
      id: 1,
      data: t(
        "terms_and_conditions.disclaimer.list.general_disclaimer_of_warranties"
      ),
    },
    {
      id: 2,
      data: t("terms_and_conditions.disclaimer.list.content_provided_as_is"),
    },
    {
      id: 3,
      data: t(
        "terms_and_conditions.disclaimer.list.no_warranty_on_specifics.title"
      ),
    },
    {
      id: "a",
      data: t(
        "terms_and_conditions.disclaimer.list.no_warranty_on_specifics.a"
      ),
    },
    {
      id: "b",
      data: t(
        "terms_and_conditions.disclaimer.list.no_warranty_on_specifics.b"
      ),
    },
    {
      id: "c",
      data: t(
        "terms_and_conditions.disclaimer.list.no_warranty_on_specifics.c"
      ),
    },
    {
      id: "d",
      data: t(
        "terms_and_conditions.disclaimer.list.no_warranty_on_specifics.d"
      ),
    },
    {
      id: 4,
      data: t("terms_and_conditions.disclaimer.list.limitation_of_liability"),
    },
  ],

  listTen: [
    {
      id: 1,
      data: t(
        "terms_and_conditions.transfer_of_rights.list.transfer_by_company"
      ),
    },
    {
      id: 2,
      data: t("terms_and_conditions.transfer_of_rights.list.transfer_by_user"),
    },
  ],

  listTwelve: [
    {
      id: 1,
      data: t(
        "terms_and_conditions.miscellaneous.list.separation_of_paragraphs"
      ),
    },
    {
      id: 2,
      data: t("terms_and_conditions.miscellaneous.list.delay_in_enforcement"),
    },
    {
      id: 3,
      data: t("terms_and_conditions.miscellaneous.list.governing_law"),
    },
    {
      id: 4,
      data: t("terms_and_conditions.miscellaneous.list.dispute_resolution"),
    },
  ],
};
