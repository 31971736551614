import ActionSheet from "actionsheet-react";
import { t } from "i18next";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { menu_items } from "../assets/content/data";
import "../assets/styles/layout/navbar.css";
import { RedLogoutIcon } from "../assets/svgs/navbar";
import { loadData } from "../redux/slices/activity";
import { logoutUser } from "../redux/slices/auth";

const Navbar = () => {
  const menuRef = useRef();
  const logoutRef = useRef();
  const dispatch = useDispatch();

  const openMenu = () => {
    menuRef.current.open();
  };

  const closeMenu = () => {
    menuRef.current.close();
  };

  const openLogout = () => {
    logoutRef.current.open();
  };

  const closeLogout = () => {
    logoutRef.current.close();
  };

  const logout = () => {
    dispatch(logoutUser());
    closeLogout();
    closeMenu();
  };

  useEffect(() => {
    dispatch(loadData());
  }, []);

  const data = useSelector((state) => state.activityState.data);

  return (
    <div className="position-relative min-vh-100 d-flex flex-column">
      <div className="grad-blue-pink navbar-wrapper common-padding-x d-flex justify-content-between align-items-center text-center">
        <button onClick={openMenu}>
          <MenuIcon />
        </button>
        <div className="user-info">
          <p className="m-0 fs-16 fw-bold text-white lh-18">{data.full_name}</p>
          <p className="m-0 fs-14 fw-light text-white opacity-50 lh-18">
            {data.user_name}
          </p>
        </div>
        <button onClick={openLogout}>
          <LogoutIcon />
        </button>
      </div>
      <Outlet />
      <MenuSheet menuRef={menuRef} logout={logout} />
      <LogoutSheet
        logoutRef={logoutRef}
        logout={logout}
        closeLogout={closeLogout}
      />
    </div>
  );
};

const MenuSheet = ({ menuRef, logout }) => (
  <ActionSheet
    ref={menuRef}
    sheetStyle={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
    sheetTransition={"transform 0.3s ease-in-out"}
    bgTransition="opacity 0.3s ease-in-out"
    touchEnable
    closeOnBgTap
  >
    <div className="common-padding-x text-start logout-wrapper menu-wrapper">
      {menu_items.map((item, _) => (
        <Link
          to={item.path}
          key={_}
          className="d-block text-black fs-14 fw-bold"
        >
          <span>{<item.icon />}</span>
          <span>{item.label}</span>
        </Link>
      ))}
      <button
        onClick={logout}
        className="d-block text-black fs-14 fw-bold w-100 text-start bg-transparent"
      >
        <span>{<RedLogoutIcon />}</span>
        <span>{t("Logout")}</span>
      </button>
    </div>
  </ActionSheet>
);

const LogoutSheet = ({ logoutRef, closeLogout, logout }) => (
  <ActionSheet
    ref={logoutRef}
    sheetStyle={{
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    }}
    sheetTransition={"transform 0.3s ease-in-out"}
    bgTransition="opacity 0.3s ease-in-out"
    touchEnable
    closeOnBgTap
  >
    <div className="common-padding-x text-center logout-wrapper">
      <p className="fs-20 fw-bold text-black">{t("Log Out")}</p>
      <p className="fs-14 fw-regular text-black">
        {t(
          "All account data of @account_name will be deleted. Do you really want to log out?",
          { account_name: "account_names" }
        )}
      </p>
      <div className="d-flex justify-content-between gap-2 align-items-center">
        <button className="flex-fill text-white fs-14 fw-bold" onClick={logout}>
          {t("Log Out")}
        </button>
        <button
          className="flex-fill text-white fs-14 fw-bold"
          onClick={closeLogout}
        >
          {t("Cancel")}
        </button>
      </div>
    </div>
  </ActionSheet>
);

const LogoutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.289"
    height="20.377"
    viewBox="0 0 23.289 20.377"
  >
    <path
      id="Icon_open-account-logout"
      data-name="Icon open-account-logout"
      d="M14.555,0V2.911H2.911V17.466H14.555v2.911H0V0Zm2.911,5.822,5.822,4.367-5.822,4.367V11.644H5.822V8.733H17.466Z"
      fill="#fff"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="15.75"
    viewBox="0 0 27 15.75"
  >
    <g
      id="Icon_ionic-ios-menu"
      data-name="Icon ionic-ios-menu"
      transform="translate(-4.5 -10.125)"
    >
      <path
        id="Path_31"
        data-name="Path 31"
        d="M30.375,12.375H5.625A1.128,1.128,0,0,1,4.5,11.25h0a1.128,1.128,0,0,1,1.125-1.125h24.75A1.128,1.128,0,0,1,31.5,11.25h0A1.128,1.128,0,0,1,30.375,12.375Z"
        fill="#fff"
      />
      <path
        id="Path_32"
        data-name="Path 32"
        d="M30.375,19.125H5.625A1.128,1.128,0,0,1,4.5,18h0a1.128,1.128,0,0,1,1.125-1.125h24.75A1.128,1.128,0,0,1,31.5,18h0A1.128,1.128,0,0,1,30.375,19.125Z"
        fill="#fff"
      />
      <path
        id="Path_33"
        data-name="Path 33"
        d="M30.375,25.875H5.625A1.128,1.128,0,0,1,4.5,24.75h0a1.128,1.128,0,0,1,1.125-1.125h24.75A1.128,1.128,0,0,1,31.5,24.75h0A1.128,1.128,0,0,1,30.375,25.875Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default Navbar;
