import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./slices/auth";
import ActivitySlice from "./slices/activity";

// import fetchDemoOpenAiReducer from "../redux/reducers/demo";
// import imageLoadReducer from "../redux/reducers/image";
// import AuthSlice from "../slices/AuthSlice";

export const store = configureStore({
  reducer: {
    authState: AuthSlice,
    activityState: ActivitySlice,
    // demoOpenAi: fetchDemoOpenAiReducer,
    // images: imageLoadReducer,
  },
});
