import { addS3Url } from "../../utils/utils";

export const aws_images = {
  growingUser: addS3Url("/payment/grow-user.png"),
  featuredMask1: addS3Url("/payment/featured-mask-1.png"),
  featured1: addS3Url("/payment/featured-1.png"),
  featuredMask2: addS3Url("/payment/featured-mask-2.png"),
  featured2: addS3Url("/payment/featured-2.png"),
  featuredMask3: addS3Url("/payment/featured-mask-3.png"),
  featured3: addS3Url("/payment/featured-3.png"),
  featuredMask4: addS3Url("/payment/featured-mask-4.png"),
  featured4: addS3Url("/payment/featured-4.png"),
  featuredMask5: addS3Url("/payment/featured-mask-5.png"),
  featured5: addS3Url("/payment/featured-5.png"),
  // featuredMask1: FeatureMask1,
  // featured1: Feature1,
  // featuredMask2: FeatureMask2,
  // featured2: Feature2,
  // featuredMask3: FeatureMask3,
  // featured3: Feature3,
  // featuredMask4: FeatureMask4,
  // featured4: Feature4,
  // featuredMask5: FeatureMask5,
  // featured5: Feature5,
  activity_profile: addS3Url("/activity/profile.png"),
};

export const aws_videos = {
  // testimonial1: testimonial1,
  // testimonial2: testimonial2,
  // testimonial3: testimonial3,
  // testimonial4: testimonial4,
  // testimonial5: testimonial5,
  testimonial1: addS3Url("/videos/Testimonial_1.mp4"),
  testimonial2: addS3Url("/videos/Testimonial_2.mp4"),
  testimonial3: addS3Url("/videos/Testimonial_3.mp4"),
  testimonial4: addS3Url("/videos/Testimonial_4.mp4"),
  testimonial5: addS3Url("/videos/Testimonial_5.mp4"),
};
