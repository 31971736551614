import { t } from "i18next";
import React, { Suspense } from "react";
import "../../assets/styles/pages/activity.css";
// import MonthGraph from "../../components/activity/MonthGraph";
// import WeekGraph from "../../components/activity/WeekGraph";
import { useSelector } from "react-redux";
import DefaultProfile from "../../assets/images/profile/default_profile.jpg";
import Footer from "../../components/common/Footer";
const MonthGraph = React.lazy(() =>
  import("../../components/activity/MonthGraph")
);

const WeekGraph = React.lazy(() =>
  import("../../components/activity/WeekGraph")
);

const Skeleton = ({ className }) => (
  <div className={`skeleton-box ${className || ""}`}></div>
);

const WrapSkeleton = ({ loading, className, children }) =>
  loading ? <Skeleton className={className} /> : <>{children}</>;

const ErrorText = ({ message }) => (
  <div className="error-wrapper d-flex justify-content-center align-items-center">
    <p className="error-text m-0 fs-12">{message}</p>
  </div>
);

const Activity = () => {
  const { data, loading, error } = useSelector((state) => state.activityState);

  return (
    <div className="common-padding-x common-padding-y flex-grow-1 d-flex flex-column">
      <div className="flex-grow-1">
        <div className="grad-blue-purple-orange profile-wrapper d-flex justify-content-around align-items-center text-center text-white">
          <div>
            <WrapSkeleton
              className={"skeleton-followers-text"}
              loading={loading}
            >
              <p className="open-sans-800 fs-20 ls-20 m-0">
                {data.total_followers || 0}
              </p>
            </WrapSkeleton>
            <p className="fw-regular fs-14 m-0">{t("Followers")}</p>
          </div>
          <div className="profile-img-wrapper d-flex justify-content-center align-items-center">
            <img
              height={79}
              width={79}
              src={data?.profile_image_url || "/images/profile.png"}
              alt={data.full_name || "Profile"}
              onError={(e) => {
                e.target.src = DefaultProfile;
                e.target.onerror = null;
              }}
              className="profile-img w-100"
            />
          </div>
          <div>
            <WrapSkeleton
              className={"skeleton-followers-text"}
              loading={loading}
            >
              <p className="open-sans-800 fs-20 ls-20 m-0">
                {data.total_following || 0}
              </p>
            </WrapSkeleton>
            <p className="fw-regular fs-14 m-0">{t("Following")}</p>
          </div>
        </div>
        <div className="common-padding-y pb-0">
          <div>
            <div className="d-flex justify-content-between align-items-center mb-1">
              <p className="open-sans-800 fs-14 ls-20 text-black m-0">
                {t("7-Day Activity")}
              </p>
              <div className="fw-bold fs-10 text-right d-flex justify-content-right align-items-center high-low-wrapper">
                <p className="high-text  d-flex justify-content-center align-items-center m-0">
                  <span>
                    <UpIcon />
                  </span>
                  <WrapSkeleton className={"skeleton-text"} loading={loading}>
                    <span>
                      {data?.weekly_activity_summary?.positive_interactions}
                    </span>
                  </WrapSkeleton>
                </p>
                <p className="low-text d-flex justify-content-center align-items-center m-0">
                  <span>
                    <DownIcon />
                  </span>
                  <WrapSkeleton className={"skeleton-text"} loading={loading}>
                    <span>
                      {data?.weekly_activity_summary?.negative_interactions}
                    </span>
                  </WrapSkeleton>
                </p>
              </div>
            </div>
            <div className="activity-line w-100" />
            <WrapSkeleton
              className={"skeleton-graph-wrapper"}
              loading={loading}
            >
              <Suspense
                fallback={<Skeleton className="skeleton-graph-wrapper" />}
              >
                {error ? <ErrorText {...error} /> : <WeekGraph />}
              </Suspense>
            </WrapSkeleton>
          </div>
          <div>
            <div className="d-flex justify-content-between align-items-center mb-1">
              <p className="open-sans-800 fs-14 ls-20 text-black m-0">
                {t("30-Day Activity")}
              </p>
              <div className="fw-bold fs-10 text-right d-flex justify-content-right align-items-center high-low-wrapper">
                <p className="high-text  d-flex justify-content-center align-items-center m-0">
                  <span>
                    <UpIcon />
                  </span>
                  <WrapSkeleton className={"skeleton-text"} loading={loading}>
                    <span>
                      {data?.monthly_activity_summary?.positive_interactions}
                    </span>
                  </WrapSkeleton>
                </p>
                <p className="low-text d-flex justify-content-center align-items-center m-0">
                  <span>
                    <DownIcon />
                  </span>
                  <WrapSkeleton className={"skeleton-text"} loading={loading}>
                    <span>
                      {data?.monthly_activity_summary?.negative_interactions}
                    </span>
                  </WrapSkeleton>
                </p>
              </div>
            </div>
            <div className="activity-line w-100" />
            <WrapSkeleton
              className={"skeleton-graph-wrapper"}
              loading={loading}
            >
              <Suspense
                fallback={<Skeleton className="skeleton-graph-wrapper" />}
              >
                {error ? <ErrorText {...error} /> : <MonthGraph />}
              </Suspense>
            </WrapSkeleton>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const UpIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="8"
    viewBox="0 0 10 8"
  >
    <path
      id="Polygon_2"
      data-name="Polygon 2"
      d="M4.152,1.357a1,1,0,0,1,1.7,0l3.2,5.113A1,1,0,0,1,8.2,8H1.8A1,1,0,0,1,.956,6.47Z"
      fill="#66ae08"
    />
  </svg>
);

const DownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="8"
    viewBox="0 0 10 8"
  >
    <path
      id="Polygon_2"
      data-name="Polygon 2"
      d="M4.152,1.357a1,1,0,0,1,1.7,0l3.2,5.113A1,1,0,0,1,8.2,8H1.8A1,1,0,0,1,.956,6.47Z"
      transform="translate(10 8) rotate(180)"
      fill="#ee1e52"
    />
  </svg>
);
export default Activity;
