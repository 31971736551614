export const generateRandomCode = () => {
  let randomNumber = Math.floor(Math.random() * 10000);

  let paddedNumber = randomNumber.toString().padStart(4, "0");

  return paddedNumber;
};

export const addS3Url = (url) => {
  return process.env.REACT_APP_S3_URL + url;
};
