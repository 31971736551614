import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { support_email } from "../../../assets/content/data";

const PrivacyPolicy = () => {
  return (
    <div className="py-3">
      <div className="text-center d-flex flex-column pb-4 min-vh-100">
        <div className="d-flex position-relative justify-content-center border border-black border-top-0 border-start-0 border-end-0 pb-3">
          <div>
            <Link
              to={-1}
              style={{ left: "15px" }}
              className="position-absolute"
            >
              <svg
                style={{
                  borderRadius: "50%",
                  border: "1px solid #000",
                  cursor: "pointer",
                }}
                width="26px"
                height="26px"
                viewBox="0 0 1024 1024"
                fill="#000000"
                className="icon"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M669.6 849.6c8.8 8 22.4 7.2 30.4-1.6s7.2-22.4-1.6-30.4l-309.6-280c-8-7.2-8-17.6 0-24.8l309.6-270.4c8.8-8 9.6-21.6 2.4-30.4-8-8.8-21.6-9.6-30.4-2.4L360.8 480.8c-27.2 24-28 64-0.8 88.8l309.6 280z" />
              </svg>
            </Link>
            <h4 className="m-0 p-0">{t("privacy_policy.title")}</h4>
          </div>
        </div>
        <div
          style={{ textAlign: "left" }}
          className="d-flex flex-column px-4 mt-4"
        >
          <h3 className="mt-2">{t("privacy_policy.commitment_title")}</h3>
          <p>{t("privacy_policy.commitment_text")}</p>
          <h3 className="mt-2">{t("privacy_policy.info_collection_title")}</h3>
          <p>{t("privacy_policy.info_collection_text")}</p>
          <h3 className="mt-2">{t("privacy_policy.info_storage_title")}</h3>
          <p>{t("privacy_policy.info_storage_text")}</p>
          <h3 className="mt-2">{t("privacy_policy.info_usage_title")}</h3>
          <p>{t("privacy_policy.info_usage_text")}</p>

          <h3 className="mt-2">
            {t("privacy_policy.info_storage_duration_title")}
          </h3>
          <p>{t("privacy_policy.info_storage_duration_text")}</p>

          <h3 className="mt-2">{t("privacy_policy.info_protection_title")}</h3>
          <p>{t("privacy_policy.info_protection_text")}</p>

          <h3 className="mt-2">{t("privacy_policy.user_protection_title")}</h3>
          <p>{t("privacy_policy.user_protection_text")}</p>

          <h3 className="mt-2">{t("privacy_policy.disclosure_title")}</h3>
          <p>{t("privacy_policy.disclosure_text")}</p>

          <h3 className="mt-2">{t("privacy_policy.not_for_children_title")}</h3>
          <p>{t("privacy_policy.not_for_children_text")}</p>

          <h3 className="mt-2">{t("privacy_policy.policy_changes_title")}</h3>
          <p>{t("privacy_policy.policy_changes_text")}</p>

          <h3 className="mt-2">{t("privacy_policy.contact_title")}</h3>
          <p>
            <Trans
              i18nKey={"privacy_policy.contact_text"}
              components={{ tag: <Link to={`"mailto:${support_email}`} /> }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
