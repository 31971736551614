import { t } from "i18next";
import React, { useEffect, useState } from "react";
import blurredGrowingImage from "../../assets/blurred/payment/grow-user.webp";
import { aws_images } from "../../assets/content/images";
import Button from "../common/Button";
import ImageLoader from "../image/ImageLoader";
const growthText = [t("success"), t("fans"), t("growth"), t("engagement")];

const StartGrowing = ({ scrollToPlan }) => {
  const [currentText, setCurrentText] = useState(0);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true);
      setTimeout(() => {
        setCurrentText((prevText) => (prevText + 1) % growthText.length);
        setIsFading(false);
      }, 150);
    }, 2000);
    return () => clearInterval(interval);
  });

  return (
    <div className="grad-lavender-white-pink-v">
      <div className="d-flex flex-column justify-content-center align-items-center payment-padding-x">
        <div className="mt-2">
          <UsersIcon />
        </div>
        <p className="grad-text-blue-pink fw-bold text-center m-0">
          <span className="grad-text-blue-pink fs-26 lh-25">
            {t("Real, organic social")}
          </span>
          <br />
          <span
            className={`grad-text-blue-pink opacity-ani fs-26 ${
              isFading ? "fade-out" : "fade-in"
            }`}
          >
            {t("media")}
            <span className="fs-30 grad-text-blue-pink fw-bold lh-25">
              {" "}
              {growthText[currentText]}
            </span>
          </span>
        </p>
        <p className="payment-section-v text-center fs-14 fw-regular text-black mt-3 mb-4">
          {t(
            "Forget automated bots and generic tools. AskMe ensures authentic growth by connecting you with your audience through AI-driven precision."
          )}
        </p>
        <Button onClick={scrollToPlan} label={t("Start Growing Today")} />
        <div className="plan-wrapper position-relative payment-section-v">
          <p className="position-absolute top-0 start-50 translate-middle fs-12 m-0 text-nowrap offer-text open-sans-800">
            {t("Limited-Time Offer")}
          </p>
          <p className="fs-12 fw-regular text-black m-0 text-center">
            <span className="fs-14 grad-text-yellow-orange open-sans-800 off-text">
              {t("90% OFF")}{" "}
            </span>
            <span>{t("FLASH SALE")}</span>
          </p>
        </div>
      </div>
      <ImageLoader
        src={aws_images.growingUser}
        placeHolderImage={blurredGrowingImage}
        alt="start growing"
        className="grow-img"
      />
    </div>
  );
};

const UsersIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="97"
    height="97"
    viewBox="0 0 97 97"
    className="growing-user-m"
  >
    <defs>
      <linearGradient
        id="linear-gradient6"
        x1="1"
        x2="-0.008"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#8a2fbc" />
        <stop offset="0.494" stopColor="#f9466b" />
        <stop offset="1" stopColor="#fdba66" />
      </linearGradient>
      <filter
        id="Rectangle_2"
        x="0"
        y="0"
        width="97"
        height="97"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dx="5" dy="5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="7.5" result="blur" />
        <feFlood floodOpacity="0.149" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Logo" transform="translate(23.5 23.5)">
      <g
        transform="matrix(1, 0, 0, 1, -18.36, -18.36)"
        filter="url(#Rectangle_2)"
      >
        <rect
          id="Rectangle_2-2"
          data-name="Rectangle 2"
          width="50"
          height="50"
          rx="5"
          transform="translate(18.5 18.5)"
          stroke="#fff"
          strokeWidth="2"
          fill="url(#linear-gradient6)"
        />
      </g>
      <g
        id="Group_25"
        data-name="Group 25"
        transform="translate(44.453 39.946) rotate(180)"
      >
        <path
          id="Path_6"
          data-name="Path 6"
          d="M7.877,1.212H1.818a.609.609,0,0,0-.606.606A6.7,6.7,0,0,0,7.877,8.482h.015a4.862,4.862,0,1,1-2.614.762A7.9,7.9,0,0,1,0,1.818,1.826,1.826,0,0,1,1.818,0H7.877a.606.606,0,0,1,0,1.212ZM4.241,13.33A3.635,3.635,0,1,0,7.877,9.694,3.653,3.653,0,0,0,4.241,13.33Z"
          transform="translate(0 4.304)"
          fill="#fff"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M7.877,17H1.818a.609.609,0,0,1-.606-.606A6.7,6.7,0,0,1,7.877,9.724h.015a4.862,4.862,0,1,0-2.614-.762A7.9,7.9,0,0,0,0,16.389a1.826,1.826,0,0,0,1.818,1.818H7.877a.606.606,0,0,0,0-1.212ZM4.241,4.877A3.635,3.635,0,1,1,7.877,8.513,3.653,3.653,0,0,1,4.241,4.877Z"
          transform="translate(38.623 22.511) rotate(180)"
          fill="#fff"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M9.853,6.4H6.4V9.853a.985.985,0,0,1-1.971,0V6.4H.985a.985.985,0,0,1,0-1.971H4.434V.985A.985.985,0,1,1,6.4.985V4.434H9.853a.985.985,0,1,1,0,1.971Z"
          transform="translate(21.256 1.971)"
          fill="#fff"
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M12.81,1.971H2.956a.99.99,0,0,0-.985.985A10.9,10.9,0,0,0,12.81,13.795h.024a7.907,7.907,0,1,1-4.251,1.24A12.847,12.847,0,0,1,0,2.956,2.97,2.97,0,0,1,2.956,0H12.81a.985.985,0,0,1,0,1.971ZM6.9,21.678a5.912,5.912,0,1,0,5.912-5.912A5.94,5.94,0,0,0,6.9,21.678Z"
          transform="translate(6.476)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default StartGrowing;
