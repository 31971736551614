import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { expectations, working_process } from "../../assets/content/data";
import TextureImg from "../../assets/images/payment/texture.png";

const CoveredPoints = () => {
  return (
    <div className="covered-points-container">
      <div className="payment-section-pv payment-padding-x text-center">
        <p className="fs-20 text-black open-sans-800 payment-section-b">
          {t(
            "Struggling with Instagram Growth? Join AskMe, We've Got You Covered!"
          )}
        </p>
        <p className="text-start fs-14 lh-20">
          <Trans
            i18nKey={
              "Imagine waking up every day excited about the work you do. With AskMe, you can take control of your career and carve out a path that’s as flexible as it is fulfilling. Whether you’re building a personal brand, growing a business, or pursuing influencer opportunities, the possibilities are endless. AskMe is designed to help you create an Instagram presence that attracts the audience you’ve always dreamed of. From loyal followers to collaborations with top brands, we’ll guide you every step of the way to achieve your goals. Here’s what you can expect when you partner with AskMe:"
            }
            components={{ tag: <br /> }}
          />
        </p>
        {expectations.map((expectation_array, _) => (
          <div className="d-flex" key={_}>
            {expectation_array.map((expectation, _) => (
              <div key={_} className="w-50 expected-point">
                <p className="fs-12 fw-regular text-start">
                  <span className="me-2">
                    <TrueIcon />
                  </span>
                  <span className="text-black">{expectation}</span>
                </p>
              </div>
            ))}
          </div>
        ))}
        <p className="more-text fw-bold fs-14 text-start">
          {t("And so much more...")}
        </p>
      </div>
      <div className="grad-crimson-magenta-purple strategy-wrapper text-white text-center d-flex flex-column align-items-center justify-content-center">
        <img src={TextureImg} alt="texture" />
        <div>
          <p className="fs-20 open-sans-800 m-0">{t("How it Works")}</p>
          <p className="fs-14 fw-regular">{t("The 3-Step Strategy Process")}</p>
        </div>
        {working_process.map((process, _) => (
          <div key={_}>
            <process.icon />
            <p className="fs-20 open-sans-800 m-0 mt-2">{process.title}</p>
            <p className="fs-14 fw-regular mb-1 mt-1">{process.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const TrueIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.068"
    height="10.61"
    viewBox="0 0 14.068 10.61"
  >
    <path
      id="Icon_feather-check"
      data-name="Icon feather-check"
      d="M9.459,18.11A1.5,1.5,0,0,1,8.4,17.67L4.939,14.211A1.5,1.5,0,0,1,7.061,12.09l2.4,2.4,6.549-6.549a1.5,1.5,0,1,1,2.121,2.121L10.52,17.67A1.5,1.5,0,0,1,9.459,18.11Z"
      transform="translate(-4.5 -7.5)"
      fill="#289155"
    />
  </svg>
);

export default CoveredPoints;
