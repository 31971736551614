import React from "react";

const Button = ({ label, btnRef, hideArrow, onClick, ...props }) => {
  return (
    <button
      ref={btnRef || null}
      {...props}
      className={`grad-blue-purple-orange payment-btn ${props.className || ""}`}
      onClick={onClick}
    >
      {label} {!hideArrow && <ArrowIcon />}
    </button>
  );
};

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6.767"
    height="12.317"
    viewBox="0 0 6.767 12.317"
    style={{ marginLeft: "2px" }}
  >
    <path
      id="Icon_ionic-ios-arrow-forward2"
      data-name="Icon ionic-ios-arrow-forward2"
      d="M15.974,12.353,11.495,7.7a.9.9,0,0,1,0-1.243.829.829,0,0,1,1.2,0l5.074,5.277a.9.9,0,0,1,.025,1.214l-5.1,5.314a.827.827,0,0,1-1.2,0,.9.9,0,0,1,0-1.243Z"
      transform="translate(-11.246 -6.196)"
      fill="#fff"
    />
  </svg>
);

export default Button;
