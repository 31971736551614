export const LogoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="143"
    height="143"
    viewBox="0 0 143 143"
  >
    <defs>
      <linearGradient
        id="linear-gradient9"
        x1="1"
        x2="-0.008"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#8a2fbc" />
        <stop offset="0.494" stopColor="#f9466b" />
        <stop offset="1" stopColor="#fdba66" />
      </linearGradient>
      <filter
        id="Rectangle_2"
        x="0"
        y="0"
        width="143"
        height="143"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dx="5" dy="5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="7.5" result="blur" />
        <feFlood floodOpacity="0.149" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Logo" transform="translate(17.885 17.885)">
      <g
        transform="matrix(1, 0, 0, 1, -17.88, -17.89)"
        filter="url(#Rectangle_2)"
      >
        <rect
          id="Rectangle_2-2"
          data-name="Rectangle 2"
          width="96"
          height="96"
          rx="5"
          transform="translate(18.5 18.5)"
          stroke="#fff"
          strokeWidth="2"
          fill="url(#linear-gradient9)"
        />
      </g>
      <g
        id="Group_25"
        data-name="Group 25"
        transform="translate(86.059 77.321) rotate(180)"
      >
        <path
          id="Path_6"
          data-name="Path 6"
          d="M15.272,2.35H3.524A1.18,1.18,0,0,0,2.35,3.524,13,13,0,0,0,15.272,16.447H15.3a9.428,9.428,0,1,1-5.068,1.478A15.317,15.317,0,0,1,0,3.524,3.541,3.541,0,0,1,3.524,0H15.272a1.175,1.175,0,0,1,0,2.35Zm-7.049,23.5A7.049,7.049,0,1,0,15.272,18.8,7.082,7.082,0,0,0,8.223,25.845Z"
          transform="translate(0 8.346)"
          fill="#fff"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M15.272,32.953H3.524A1.18,1.18,0,0,1,2.35,31.778,13,13,0,0,1,15.272,18.855H15.3a9.428,9.428,0,1,0-5.068-1.478A15.317,15.317,0,0,0,0,31.778,3.541,3.541,0,0,0,3.524,35.3H15.272a1.175,1.175,0,0,0,0-2.35ZM8.223,9.457a7.049,7.049,0,1,1,7.049,7.049A7.082,7.082,0,0,1,8.223,9.457Z"
          transform="translate(74.888 43.648) rotate(180)"
          fill="#fff"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M19.105,12.418H12.418v6.687a1.911,1.911,0,0,1-3.821,0V12.418H1.911a1.911,1.911,0,0,1,0-3.821H8.6V1.911a1.911,1.911,0,1,1,3.821,0V8.6h6.687a1.911,1.911,0,1,1,0,3.821Z"
          transform="translate(41.214 3.821)"
          fill="#fff"
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M24.837,3.821H5.732A1.92,1.92,0,0,0,3.821,5.732,21.141,21.141,0,0,0,24.837,26.747h.047a15.332,15.332,0,1,1-8.242,2.4A24.909,24.909,0,0,1,0,5.732,5.759,5.759,0,0,1,5.732,0H24.837a1.911,1.911,0,0,1,0,3.821ZM13.374,42.032A11.463,11.463,0,1,0,24.837,30.569,11.518,11.518,0,0,0,13.374,42.032Z"
          transform="translate(12.556)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
