import { t } from "i18next";
import React, { useState } from "react";
import { faqs } from "../../assets/content/data";

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <>
      <p className="fs-20 text-black open-sans-800 payment-section-b text-center mb-2">
        {t("FAQs: We’re Here to Help")}
      </p>
      <p className="fs-15 text-black fw-regular text-center mb-3">
        {t(
          "Still unsure? Reach out to us via email or live chat, and we’ll answer all your questions!"
        )}
      </p>
      <div>
        {faqs.map((faq, _) => (
          <div key={_} className="accordion-item">
            <button
              className="accordion-title text-start w-100 bg-transparent fs-12 fw-bold d-flex justify-content-between align-items-center"
              onClick={() => toggleAccordion(_)}
            >
              <span className="text-black">{faq.title}</span>
              <div className="fs-12 fw-semibold d-flex align-items-center">
                {activeIndex === _ ? <MinusIcon /> : <PlusIcon />}
              </div>
            </button>
            {activeIndex === _ && (
              <div className="accordion-content fs-12 text-black text-start">
                {faq.content}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

const MinusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.385"
    height="2"
    viewBox="0 0 9.385 2"
  >
    <rect width="9.385" height="2" fill="#6432ff" />
  </svg>
);

export const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.385"
    height="9.688"
    viewBox="0 0 9.385 9.688"
  >
    <path
      id="Path_107"
      data-name="Path 107"
      d="M6.377-7.725H10.4v1.348H6.377v4.16H5.02v-4.16h-4V-7.725h4V-11.9H6.377Z"
      transform="translate(-1.016 11.904)"
      fill="#6432ff"
    />
  </svg>
);

export default Faqs;
